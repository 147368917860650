import React, { useState } from 'react';
import { FormGroup, Row, Col, Button, Spinner } from 'reactstrap';
import ImageUpload from 'Components/Common/Form/upload';
import { Formik, Form as FormikForm, Field, FieldArray } from 'formik';
import { addresses, initialValues, validationSchema } from './validation';
import { errorMessage } from 'Helpers/Validation';
import { updateTopbarProfile } from 'Redux/Actions/auth.action';
import {
    Input,
    RadioGroup,
    Select,
    CustomInput,
    DatePicker,
} from 'Components/Common/Form/elements';
import Swal from 'sweetalert2';
import Api from 'Helpers/Api';
import classnames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BIRTH_VERIFICATION_TYPES } from 'Helpers/constants';
import { COUNTRIES } from 'Helpers/constants';
import { ADDRESS_VERIFICATION_TYPES } from 'Helpers/constants';

const BasicInfo = ({
    profileDetails,
    getProfileDetails,
    setActiveTab,
    usStates,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const apiPayload = (values) => {
        const { id } = profileDetails;
        let data = { ...values };
        delete data.userHomeAddressDto;
        delete data.homeAddressVerification;
        delete data.homeAddressIssuedDate;
        delete data.homeAddressDocumentData;
        const payload = {
            updateProfileDto: {
                ...data,
                id: id,
                dateOfBirthVerification: values.dateOfBirthVerification?.value || 0,
                dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
                isHomeless: data.isHomeless === 'true',
                dateOfBirthDocumentData: profileDetails.dateOfBirthDocumentData === values.dateOfBirthDocumentData ? "" : values.dateOfBirthDocumentData,
                imageData: profileDetails.avatar === values.imageData ? "" : values.imageData
            },
            homeAddressVerification: values.homeAddressVerification?.value || 0,
            homeAddressIssuedDate: values.homeAddressIssuedDate,
            homeAddressDocumentData: profileDetails.homeAddressDocumentData === values.homeAddressDocumentData ? "" : values.homeAddressDocumentData
        }

        if (data.differentHomeAddress === "false" || !data.differentHomeAddress) {
            payload.userHomeAddressDto = {
                profileId: id,
                isPrimary: true,
                ...values.userHomeAddress,
                state: values.userHomeAddress.state === null
                    ? null :
                    values.userHomeAddress.state?.value || values.userHomeAddress.state,
            }
            payload.userMailingAddressDto = {
                ...payload.userHomeAddressDto
            }
        } else {
            addresses.forEach(({ field }) => {
                payload[`${field}Dto`] = {
                    profileId: id,
                    isPrimary: true,
                    ...values[field],
                    state: values[field].state === null ? null : values[field].state?.value || values[field].state,
                };
            });
        }

        delete payload.updateProfileDto.userName;
        delete payload.updateProfileDto.differentHomeAddress;
        delete payload.updateProfileDto.userHomeAddress;
        delete payload.updateProfileDto.userMailingAddress;
        delete payload.updateProfileDto.age;

        return payload;
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            // Modify fields as per API request
            const data = apiPayload(values);

            const { organizationId } = props.participants;
            const { username } = props.match.params;

            if (username && organizationId) {
                data.organizationId = organizationId;
                
                if (data.updateProfileDto.id === undefined) {
                    data.updateProfileDto.id = props.participants.participantId.participant.userProfile.id;
                }
                
                if (data.updateContactDto && data.updateContactDto.profileId === undefined) {
                    data.updateContactDto.profileId = props.participants.participantId.participant.userProfile.id;
                }

                await Api.editOrgUserContactProfile(data);
            } else await Api.editContactProfile(data);
            getProfileDetails();
            props.updateTopbarProfile();
            setActiveTab('Background');
            Swal.fire({
                icon: 'success',
                title: 'Basic Profile updated successfully!',
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = ({ fileUrl, fileName }, setFieldValue) => {
        setFieldValue('imageData', fileUrl);
        setFieldValue('fileName', fileName);
    };

    const handleCancel = (setFieldValue) => {
        setFieldValue('imageData', null);
        setFieldValue('fileName', null);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues(profileDetails || {})}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, errors, touched, setFieldValue, values, setFieldTouched }) => {
                const labelClasses = (name) => errors[name] && touched[name];
                return (
                    <FormikForm onSubmit={handleSubmit}>
                        <Row>
                            <Col md='3'>
                                <FormGroup>
                                    <div className='img-avatar text-center'>
                                        <label>Avatar</label>
                                        <div className='text-center'>
                                            <ImageUpload
                                                avatar
                                                accept='image/*'
                                                onChange={(data) =>{
                                                    handleFileChange(data, setFieldValue)}
                                                }
                                                handleCancel={handleCancel}
                                                setFieldValue={setFieldValue}
                                                defaultValue={values.imageData}
                                            />
                                        </div>
                                    </div>
                                    <label>
                                        {moment(profileDetails.created).format('MMM DD, YYYY')}
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col md='9'>
                                <Row>
                                    <Col md='6'>
                                        <CustomInput
                                            label='First Name *'
                                            value='firstName'
                                            hasError={labelClasses('firstName')}
                                        />
                                    </Col>
                                    <Col md='6'>
                                        <CustomInput
                                            label='Last Name *'
                                            value='lastName'
                                            hasError={labelClasses('lastName')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <label>Middle name</label>
                                            <Field
                                                component={Input}
                                                type='text'
                                                name='middleName'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <label>User Name</label>
                                            <Field
                                                component={Input}
                                                type='text'
                                                name='userName'
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Suffix</label>
                                    <Field component={Input} name='suffix' />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup
                                    className={classnames('has-label', {
                                        'has-danger': labelClasses('dateOfBirth'),
                                    })}
                                >
                                    <label>Date of Birth *</label>
                                    <Field component={DatePicker} name='dateOfBirth' />
                                    {errorMessage('dateOfBirth')}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Age</label>
                                    <Field
                                        component={Input}
                                        type='text'
                                        name='age'
                                        value={!values.dateOfBirth ? "" : moment().diff(moment(values.dateOfBirth), 'years')}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <FormGroup className={classnames('has-label', {
                                        'has-danger': labelClasses('primaryPhone'),
                                    })}>
                                    <label>Mobile Phone Number</label>
                                    <Field
                                        component={Input}
                                        name='primaryPhone'
                                        type='text'
                                        maxLength={15}
                                    />
                                    {errorMessage('primaryPhone')}
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup className={classnames('has-label', {
                                        'has-danger': labelClasses('secondPhone'),
                                    })}>
                                    <label>Second Phone Number</label>
                                    <Field
                                        component={Input}
                                        name='secondPhone'
                                        type='text'
                                        maxLength={15}
                                    />
                                    {errorMessage('secondPhone')}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <FormGroup>
                                    <label>Birth Verification Document</label>
                                    <Field
                                        name='dateOfBirthVerification'
                                        component={Select}
                                        options={BIRTH_VERIFICATION_TYPES}
                                        placeholder='Select verifictaion type'
                                        value={values.dateOfBirthVerification}
                                    />
                                    {errorMessage('dateOfBirthVerification')}
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <label>Date of Birth Verification Issue</label>
                                    <Field component={DatePicker} name='dateOfIssue' />
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.dateOfBirthVerification &&
                            <Row className="d-flex justify-content-center">
                                <Col md='3'>
                                    <FormGroup className="text-center">
                                        <label>Upload Document</label>
                                        <ImageUpload
                                            accept='application/pdf'
                                            onChange={({ fileUrl }) =>
                                                setFieldValue('dateOfBirthDocumentData', fileUrl)
                                            }
                                            defaultValue={values.dateOfBirthDocumentData}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md='6'>
                                <CustomInput
                                    type='email'
                                    label='Personal Email *'
                                    value='personalEmail'
                                    hasError={labelClasses('personalEmail')}
                                />
                            </Col>
                            <Col md='6'>
                                <CustomInput
                                    label='Work Email'
                                    value='workEmail'
                                    hasError={labelClasses('workEmail')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <FormGroup
                                    className={classnames('has-label', {
                                        'has-danger': labelClasses('bio'),
                                    })}
                                >
                                    <label>Bio</label>
                                    <Field
                                        component={Input}
                                        type='textarea'
                                        name='bio'
                                        // value={values.bio}
                                        maxLength={150}
                                    />
                                    {errorMessage('bio')}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Are you homeless?</label>
                                    <Field
                                        component={RadioGroup}
                                        options={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false },
                                        ]}
                                        name='isHomeless'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Is mailing address different?</label>
                                    <Field
                                        component={RadioGroup}
                                        options={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false },
                                        ]}
                                        name='differentHomeAddress'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            addresses.map(({ field: address, title }) => {
                                if (address === 'userMailingAddress' && values.differentHomeAddress ? values.differentHomeAddress === 'false' : false) {
                                    return <React.Fragment key={address}></React.Fragment>;
                                }
                                return (
                                    <FieldArray key={address} name={address}>
                                        <div className='mb-4'>
                                            <h3 className='mb-1'>{title}</h3>
                                            <Row>
                                                <Col md={4}>
                                                    <CustomInput
                                                        label='Address Line 1'
                                                        value={`${address}.addressLine1`}
                                                        hasError={labelClasses(
                                                            `${address}.addressLine1`
                                                        )}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <CustomInput
                                                        label='Address Line 2'
                                                        value={`${address}.addressLine2`}
                                                        hasError={labelClasses(
                                                            `${address}.addressLine2`
                                                        )}
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <CustomInput
                                                        label='City'
                                                        value={`${address}.city`}
                                                        hasError={labelClasses(`${address}.city`)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='4'>
                                                    <label>Country</label>
                                                    <Field
                                                        component={Select}
                                                        name={`${address}.country`}
                                                        options={COUNTRIES}
                                                        value={COUNTRIES.filter(function (option) {
                                                            return option === values[address].country;
                                                        })}
                                                        getOptionValue={(option) => option}
                                                        getOptionLabel={(option) => option}
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <FormGroup
                                                        className={classnames('has-label', {
                                                            'has-danger': labelClasses(`${address}.states`),
                                                        })}
                                                    >
                                                        <label>State</label>
                                                        {values[address].country === "United States" ?
                                                            <Field
                                                                component={Select}
                                                                name={`${address}.state`}
                                                                options={usStates}
                                                                value={usStates.find(({ value: state }) => state === values[address].state)}

                                                            /> :
                                                            <Field
                                                                component={Input}
                                                                name={`${address}.state`}
                                                                value={values[address].state?.value || values[address].state}
                                                            />
                                                        }
                                                        {errorMessage(`${address}.state`)}
                                                    </FormGroup>
                                                </Col>
                                                <Col md='4'>
                                                    <CustomInput
                                                        label='Postal Code'
                                                        value={`${address}.postalCode`}
                                                        hasError={labelClasses(
                                                            `${address}.postalCode`
                                                        )}
                                                        maxLength={6}
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                address !== 'userMailingAddress' &&
                                                <>
                                                    <Row>
                                                        <Col md='12'>
                                                            <FormGroup>
                                                                <label>Address Verification Type</label>
                                                                <Field
                                                                    name='homeAddressVerification'
                                                                    component={Select}
                                                                    options={ADDRESS_VERIFICATION_TYPES}
                                                                    placeholder='Select verifictaion type'
                                                                    defaultValue={values.homeAddressVerification}
                                                                />
                                                                {errorMessage('homeAddressVerification')}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='7'>
                                                            <FormGroup>
                                                                <label>Date of Address Verification Type</label>
                                                                <Field component={DatePicker} name='homeAddressIssuedDate' />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="3">
                                                            <FormGroup className="text-center">
                                                                <label>Upload file</label>
                                                                <ImageUpload
                                                                    accept='application/pdf'
                                                                    onChange={({ fileUrl }) =>
                                                                        setFieldValue('homeAddressDocumentData', fileUrl)
                                                                    }
                                                                    defaultValue={values.homeAddressDocumentData}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </div>
                                    </FieldArray>
                                );
                            })
                        }
                        <Button
                            className='btn-fill float-right'
                            color='primary'
                            type='submit'
                        >
                            Save {isLoading && <Spinner size='sm' className='ml-2' />}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default connect(null, {
    updateTopbarProfile,
})(withRouter(BasicInfo));
