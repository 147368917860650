import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

import ProjectList from '../Project/index'
import ProjectParticipantList from './Projects/Participants'
import ProjectActivitieDateList from './Projects/Actvities/Dates'

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Table,
    Row,
    Col
} from "reactstrap";

// core components
import {
    chartExample1,
    chartExample2,
    chartExample3,
    chartExample4,
} from "./charts";

var mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    US: 2920,
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bigChartData: "data1",
        };
    }
    setBgChartData = (name) => {
        this.setState({
            bigChartData: name,
        });
    };
    render() {
        return (
            <div className="content">
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">Total Shipments</h5>
                                        <CardTitle tag="h2">Performance</CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <Button
                                                color="info"
                                                id="0"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data1",
                                                })}
                                                onClick={() => this.setBgChartData("data1")}
                                            >
                                                <input defaultChecked name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Accounts
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data2",
                                                })}
                                                onClick={() => this.setBgChartData("data2")}
                                            >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Purchases
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-gift-2" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data3",
                                                })}
                                                onClick={() => this.setBgChartData("data3")}
                                            >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Sessions
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        data={chartExample1[this.state.bigChartData]}
                                        options={chartExample1.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3" md="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-warning">
                                            <i className="tim-icons icon-chat-33" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Number</p>
                                            <CardTitle tag="h3">150GB</CardTitle>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="tim-icons icon-refresh-01" /> Update Now
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-primary">
                                            <i className="tim-icons icon-shape-star" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Followers</p>
                                            <CardTitle tag="h3">+45k</CardTitle>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="tim-icons icon-sound-wave" /> Last Research
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-success">
                                            <i className="tim-icons icon-single-02" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Users</p>
                                            <CardTitle tag="h3">150,000</CardTitle>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="tim-icons icon-trophy" /> Customers feedback
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                        <div className="info-icon text-center icon-danger">
                                            <i className="tim-icons icon-molecule-40" />
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Errors</p>
                                            <CardTitle tag="h3">12</CardTitle>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="tim-icons icon-watch-time" /> In the last hours
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Total Shipments</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-bell-55 text-primary" /> 763,215
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        data={chartExample2.data}
                                        options={chartExample2.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Daily Sales</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-delivery-fast text-info" />{" "}
                                    3,500€
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Bar
                                        data={chartExample3.data}
                                        options={chartExample3.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Completed Tasks</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-send text-success" /> 12,100K
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        data={chartExample4.data}
                                        options={chartExample4.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <ProjectActivitieDateList></ProjectActivitieDateList>
                    </Col>
                    <Col lg="6">
                        <ProjectParticipantList></ProjectParticipantList>
                    </Col>
                    <Col lg="12">
                        <ProjectList></ProjectList>
                    </Col>

                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Global Sales by Top Locations</CardTitle>
                                <p className="card-category">All products that were shipped</p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Table responsive>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/US.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>USA</td>
                                                    <td className="text-right">2.920</td>
                                                    <td className="text-right">53.23%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/DE.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Germany</td>
                                                    <td className="text-right">1.300</td>
                                                    <td className="text-right">20.43%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/AU.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Australia</td>
                                                    <td className="text-right">760</td>
                                                    <td className="text-right">10.35%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/GB.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>United Kingdom</td>
                                                    <td className="text-right">690</td>
                                                    <td className="text-right">7.87%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/RO.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Romania</td>
                                                    <td className="text-right">600</td>
                                                    <td className="text-right">5.94%</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="flag">
                                                            <img
                                                                alt="..."
                                                                src={require("Assets/img/BR.png")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>Brasil</td>
                                                    <td className="text-right">550</td>
                                                    <td className="text-right">4.34%</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col className="ml-auto mr-auto" md="6">
                                        <VectorMap
                                            map={"world_mill"}
                                            backgroundColor="transparent"
                                            zoomOnScroll={false}
                                            containerStyle={{
                                                width: "100%",
                                                height: "300px",
                                            }}
                                            regionStyle={{
                                                initial: {
                                                    fill: "#e4e4e4",
                                                    "fill-opacity": 0.9,
                                                    stroke: "none",
                                                    "stroke-width": 0,
                                                    "stroke-opacity": 0,
                                                },
                                            }}
                                            series={{
                                                regions: [
                                                    {
                                                        values: mapData,
                                                        scale: ["#AAAAAA", "#444444"],
                                                        normalizeFunction: "polynomial",
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Dashboard;
