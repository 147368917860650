import * as yup from "yup";
import { phoneRegExp } from "Helpers/Validation/regex";
import { visionOptions, hearingOptions, medicalConditionsOptions } from "./optionsData";

const updateEmergencyContactsDtoItem = {
  profileId: "",
  firstName: "",
  lastName: "",
  number: "",
  relationship: 0,
  email: ""
}

const getMultiItems = (initialValue, allData) => {
  if (!initialValue) return [];
  const initialArray = initialValue.split(",").map(num => parseInt(num));
  return allData.filter(({ value }) => initialArray.includes(value));
}

const getContacts = (data) => {
  if (!data) {
    return [updateEmergencyContactsDtoItem, updateEmergencyContactsDtoItem];
  }

  if (!data.userHealth) {
    return [updateEmergencyContactsDtoItem, updateEmergencyContactsDtoItem];
  }
  
  if (!data.userHealth.emergencyContacts) {
    return [updateEmergencyContactsDtoItem, updateEmergencyContactsDtoItem];
  }
  
  if (data.userBackground && data.userBackground.guardians) {
    const items = data.userBackground.guardians;
    let contacts = data.userHealth.emergencyContacts;

    if (contacts.length >= 2) {
      return contacts;
    }
    
    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      if (!element.isEmergencyContact) {
        continue;
      }

      contacts.push({
        profileId: "",
        firstName: element.firstName,
        lastName: element.lastName,
        number: "",
        relationship: element.relationship?.id,
        email: "",
      });
    }

    return contacts;
  }

  return data.userHealth.emergencyContacts;
}

const initialValues = (data) => {
  return {
    updateHealthDto: {
      profileId: (data && data.id) ? data.id : '',
      vision: data && data.userHealth ? getMultiItems(data.userHealth.vision, visionOptions) : "",
      hearing: data && data.userHealth ? getMultiItems(data.userHealth.hearing, hearingOptions) : "",
      medicalConditions: (data && data.userHealth) ? getMultiItems(data.userHealth.medicalConditions, medicalConditionsOptions) : "",
      healthIssues: (data && data.userHealth) ? data.userHealth.healthIssues || "" : '',
      medicalCenterName: (data && data.userHealth) ? data.userHealth.medicalCenterName || "" : '',
      doctorsFirstName: (data && data.userHealth) ? data.userHealth.doctorsFirstName || "" : '',
      doctorsLastName: (data && data.userHealth) ? data.userHealth.doctorsLastName || "" : '',
      doctorsPhone: (data && data.userHealth) ? data.userHealth.doctorsPhone || "" : '',
      doctorsEmail: (data && data.userHealth) ? data.userHealth.doctorsEmail || "" : '',
      doctorsAddress: {
        profileId: (data && data.id) ? data.id : '',
        addressLine1: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.addressLine1 : '',
        addressLine2: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.addressLine2 : '',
        city: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.city : '',
        state: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.state : '',
        country: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.country : '',
        postalCode: (data && data.userHealth && data.userHealth.doctorsAddress) ? data.userHealth.doctorsAddress.postalCode : '',
        isPrimary: false
      },
      healthInsuranceName: (data && data.userHealth) ? data.userHealth.healthInsuranceName || "" : '',
      healthInsurancePhone: (data && data.userHealth) ? data.userHealth.healthInsurancePhone || "" : '',
      insuranceId: data.userHealth?.insuranceId || "",
    },
    updateEmergencyContactsDto: getContacts(data)
  }
};

const validationSchema = yup.object().shape({
  updateHealthDto: yup.object().shape({
    healthIssues: yup.string().max(500, "Health Issues can not be more than 500 characters."),
    doctorsPhone: yup.string().max(14, "Please enter a valid phone number")
  }),
  updateEmergencyContactsDto: yup.array().of(
    yup.object().shape({
      number: yup
        .string()
        .max(15, "Number must be less than or equal to 15 characters!")
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
      email: yup.string().email("Not a valid email")
    })
  )
});

export { initialValues, validationSchema, updateEmergencyContactsDtoItem };
