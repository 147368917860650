const USER_ROLES = {
  USER: "User",
  ADMIN: "Admin",
  DIRECTOR: "Director",
  MANAGER: "Manager",
  VOLUNTEER: "Volunteer",
};

const US_STATE_ID = "231"; // 231 id of United States
const PROJECT_TYPES = [
  {
    label: "Public",
    value: true,
  },
  {
    label: "Invite-only",
    value: false,
  },
];

const AUTHORIZED_PLACE = [
  { label: "U.S. Citizen", value: 1 },
  { label: "U.S. Permanent Citizen", value: 2 },
  { label: "Alien/Refuge Lawfully Admitted to U.S.", value: 3 },
];

const RECURRENCE = ["One Time", "Recurring"];
const RECURRENCE_TYPES = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 3 },
];

const PER_PAGE = 10;

const NOTIFICATION_DISCRIMINATOR = {
  ORGANIZATION_NOTIFICATION: "OrganizationNotification",
  PROJECT_NOTIFICATION: "ProjectNotification",
  ACTIVITY_DATES_NOTIFICATION: "ActivityDatesNotification"
};

const ACTIVITY_TYPES = [
  { label: "Invite Only", value: 1 },
  { label: "Only Organization", value: 2 },
  { label: "Project Only", value: 3 },
  { label: "Public", value: 4 },
]

const PROJECTTYPE = [
  { label: "Invite Only", value: 1 },
  { label: "Organization User", value: 2 },
  { label: "Public", value: 3 },
]

const ACCEPTDENYACTION = {
  ACCEPT: 2,
  DENY: 3
}

const BIRTH_VERIFICATION_TYPES = [
  {
    label: "DD-214 (Enter date issued)",
    value: 1
  },
  {
    label: "Driver’s License (Enter expiration date. Must be current.)",
    value: 2
  },
  {
    label: "Federal, state or local government issued photo ID (Enter date issued or expiration date. Must be current.)",
    value: 3
  },
  {
    label: "Passport (Enter expiration date. Must be current.)",
    value: 4
  },
  {
    label: "School records (Enter date issued)",
    value: 5
  },
  {
    label: "Other",
    value: 6
  },
];

const ADDRESS_VERIFICATION_TYPES = [
  {
    value: 1,
    label: "ID (Unexpired Driver’s License or Gov issued ID Card with address) Enter date issued.",
    name: "ID (Unexpired Driver’s License or Gov issued ID Card with address) Enter date issued."
  },
  {
    value: 2,
    label: 'Recent Utility Bill (must be within last 90 days)'
  },
  {
    value: 3,
    label: "Recent Postmarked Envelope (must be within last 90 days)."
  },
  {
    value: 4,
    label: 'Benefits Letter Enter (must be within last 90 days)'
  },
  {
    value: 5,
    label: 'Self-Certified Form Ente (must be within last 90 days)'
  },
  {
    value: 6,
    label: 'Other'
  }
];

const GRADE_TYPES = [
  {
    value: 1,
    label: "K",
  },
  ...(new Array(12).fill(null).map((el, i) => ({
    value: i + 2,
    label: "" + (i + 1)
  }))),
  {
    value: 14,
    label: "High School Graduate",
  },
  {
    value: 15,
    label: "College Freshman"
  },
  {
    value: 16,
    label: "College Sophomore"
  },
  {
    value: 17,
    label: "AA Degree"
  },
  {
    value: 18,
    label: "College Junior"
  },
  {
    value: 19,
    label: "College Senior"
  },
  {
    value: 20,
    label: "Bachelor's Degree"
  },
  {
    value: 21,
    label: "Master's Degree"
  },
  {
    value: 22,
    label: "Doctoral Degree"
  },
  {
    value: 23,
    label: "Other"
  }
];

const SCHOOL_TYPES = [
  {
    value: 1,
    label: "Elementary"
  },
  {
    value: 2,
    label: "Secondary"
  },
  {
    value: 3,
    label: "Alternative"
  },
  {
    value: 4,
    label: "Home School"
  },
  {
    value: 5,
    label: "Adult Alternative"
  },
  {
    value: 6,
    label: "Vocational Education"
  },
  {
    value: 7,
    label: "Community College"
  },
  {
    value: 8,
    label: "University"
  }
];

const BOOLEAN_TYPES = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const COUNTRIES = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'AndorrA',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, The Democratic Republic of the',
  'Cook Islands',
  'Costa Rica',
  'Cote D\'Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic Of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Democratic People\'S Republic of',
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  'Lao People\'S Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'RWANDA',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

export {
  US_STATE_ID,
  USER_ROLES,
  PROJECT_TYPES,
  AUTHORIZED_PLACE,
  RECURRENCE,
  RECURRENCE_TYPES,
  PER_PAGE,
  NOTIFICATION_DISCRIMINATOR,
  ACTIVITY_TYPES,
  ACCEPTDENYACTION,
  PROJECTTYPE,
  BIRTH_VERIFICATION_TYPES,
  ADDRESS_VERIFICATION_TYPES,
  COUNTRIES,
  GRADE_TYPES,
  SCHOOL_TYPES,
  BOOLEAN_TYPES
};
