import { SCHOOL_TYPES } from "Helpers/constants";
import { GRADE_TYPES } from "Helpers/constants";
import { phoneRegExp } from "Helpers/Validation/regex";
import * as yup from "yup";

const initialValues = (details) => ({
  schoolName: details.schoolName || "",
  dateOfGraduation: details.dateOfGraduation ? new Date(details.dateOfGraduation) : "",
  schoolType: details.schoolType ? SCHOOL_TYPES.find(({ value }) => value === details.schoolType) : "",
  vocationalEducation: details.vocationalEducation ?? "",
  gradePointAvarage: details.gradePointAvarage || "",
  educationAwards: details.educationAwards || "",
  educationAwardsDate: details.educationAwardsDate ? new Date(details.educationAwardsDate) : "",
  schoolContactFirstName: details.schoolContactFirstName || "",
  schoolContactLastName: details.schoolContactLastName || "",
  schoolContactEmail: details.schoolContactEmail || "",
  schoolContactPhoneNumber: details.schoolContactPhone || "",
  grade: details.grade ? GRADE_TYPES.find(({ value }) => value === details.grade) : "",
  dateOfIssue: details.dateOfIssue ? new Date(details.dateOfIssue) : "",
  educationVerification: details.educationVerification || "",
  schoolAddress: {
    addressLine1: details.schoolAddress?.addressLine1 || "",
    addressLine2: details.schoolAddress?.addressLine2 || "",
    city: details.schoolAddress?.city || "",
    state: details.schoolAddress?.state || "",
    country: details.schoolAddress?.country || "",
    postalCode: details.schoolAddress?.postalCode || "",
    isPrimary: true
  }
});

const validationSchema = yup.object().shape({
  schoolName: yup
    .string()
    .max(50, "School Status can not be more than 50 characters."),
  vocationalEducation: yup
    .string()
    .max(50, "School Status can not be more than 100 characters."),
  schoolContactEmail: yup.string().email("Contact email must be a valid"),
  gradePointAvarage: yup.string().max(5, "GPA can not be more than 5 characters."),
  schoolContactPhoneNumber: yup
    .string()
    .max(25, "Phone number can not be more than 25 characters.")
    .matches(phoneRegExp, "Phone number is not valid"),
  schoolAddress: yup.object().shape({
    addressLine1: yup
      .string()
      .max(250, 'Address Line 1 can not be more than 250 characters.'),
    addressLine2: yup
      .string()
      .max(100, 'Address Line 2 can not be more than 100 characters.'),
    city: yup.string().max(150, 'City can not be more than 150 characters.'),
    postalCode: yup
      .string()
      .max(15, 'Postal code can not be more than 15 characters.')
      .optional(),
  })
});

export { initialValues, validationSchema };
