import React, { Component } from "react";
import { FormGroup, Row, Col, Input, Label } from "reactstrap";
import { RECURRENCE, RECURRENCE_TYPES } from "Helpers/constants";
import ReactSelect from "react-select";
import classnames from "classnames";
import moment from "moment";
import "./tabs.scss";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  isValidated = () => {
    const {
      recurrenceState,
      recurrenceTypeState,
      startDateState,
      endDateState,
      recurrence,
      startDate,
      endDate,
      recurrenceType,
      allWeekDays,
      weekdaysState,
      handleChange,
    } = this.props;

    if (recurrence === "Recurring" && recurrenceTypeState !== "has-succes" && !recurrenceType) {
      handleChange("has-danger", "recurrenceTypeState");
      return false;
    }

    if (recurrence === "Recurring" && recurrenceType && (recurrenceType.value === 1 || recurrenceType.value === 2) && weekdaysState !== "has-succes") {
      // one checkbox should be checked
      const anyChecked = allWeekDays.filter((w) => w.isChecked);
      if (!anyChecked.length) {
        handleChange("has-danger", "weekdaysState");
        return false;
      }
    }

    const isAfter = moment(moment(startDate).format("llll")).isAfter(
      moment(endDate).format("llll")
    );

    if (startDate && endDate && isAfter) {
      handleChange(
        `End Date field must be later than ${moment(startDate).format(
          "YYYY-MM-DD LT"
        )}`,
        "endDateError"
      );
      handleChange("has-after", "endDateState");
      return false;
    }

    if ((recurrenceState === "has-succes" && recurrence) &&
      (startDateState === "has-succes" && startDate) &&
      (endDateState === "has-succes" && endDate)
    ) {
      return true;
    }
    else {
      if (recurrenceState !== "has-succes" && !recurrence)
        handleChange("has-danger", "recurrenceState");
      if (recurrence === "Recurring" && recurrenceTypeState !== "has-succes" && !recurrenceType)
        handleChange("has-danger", "recurrenceTypeState");
        //If user click on the NEXT without selecting startDate while adding new activity 
        if (!startDate) {
          handleChange("has-danger", "startDateState");
          handleChange("Required", "startDateError");
         }
      if (startDateState === "has-danger") {
       return false;
      }
         //If user click on the NEXT without selecting endDate while adding new activity
      if (!endDate) {
        handleChange("has-danger", "endDateState");
        handleChange("Required", "endDateError");
      }
      if (endDateState === "has-danger" && endDateState !== "has-after") {
        return false;
      }
     
      if (startDate && endDate && !isAfter) {
        handleChange(
          ``,
          "endDateError"
        );
        handleChange("has-succes", "endDateState");
        return true;
      }

      return false;
    }
  };

  componentDidMount() {
    if (this.props.urlPath.includes("/edit")) {
      this.setState({
        ...this.state,
        isEditing: true,
      });
    }
  }

  render() {
    const {
      recurrence,
      recurrenceState,
      recurrenceFocus,
      recurrenceType,
      recurrenceTypeState,
      recurrenceTypeFocus,
      weekdaysState,
      startDate,
      startDateState,
      startDateError,
      startDateFocus,
      endDate,
      endDateState,
      endDateFocus,
      endDateError,
      allWeekDays,
      handleChange,
      handleCheckboxs,
    } = this.props;
   
    const OnChangeOnStartDateTime = (value) => {
      if(value){
        //To convert a to AM and p to PM
       const startDateValue = moment(value, "MM/DD/YYYY hh:mm a").format("MM/DD/YYYY hh:mm A");
        if (moment(startDateValue).isValid()) {
          handleChange(startDateValue, "startDate");
          handleChange("has-succes", "startDateState");
          handleChange("", "startDateError");
           //To set value of selected startDate
          this.props.setCallRecurringDatesAPI(true)
        }
        else {
          handleChange("has-danger", "startDateState");
          handleChange("Invalid Date Format", "startDateError");
        }
      }
      else{
        handleChange("has-danger", "startDateState");
        handleChange("Required", "startDateError");
      }

    }

    const OnChangeOnEndDateTime = (value) => {
      if(value){
         //To convert a to AM and p to PM
       const endDateValue  = moment(value, "MM/DD/YYYY hh:mm a").format("MM/DD/YYYY hh:mm A");
        if (moment(endDateValue).isValid()) {
          handleChange(endDateValue, "endDate");
          handleChange("has-succes", "endDateState");
          handleChange("", "endDateError");
          //To set value of selected endDate
          this.props.setCallRecurringDatesAPI(true)
        }
        else {
          handleChange("has-danger", "endDateState");
          handleChange("Invalid Date Format", "endDateError");
        }
      }
      else{
        handleChange("has-danger", "endDateState");
        handleChange("Required", "endDateError");
      }
    }

    return (
      <>
        <Row>
          <Col>
            <FormGroup
              className={classnames("has-label", recurrenceState, {
                "input-group-focus": recurrenceFocus,
              })}
            >
              <label>Recurrence <span className="required-mark">*</span></label>
              <ReactSelect
                onChange={(option) => handleChange(option, "recurrence")}
                className="react-select react-select-primary"
                classNamePrefix="react-select"
                value={RECURRENCE.filter(function (option) {
                  return option === recurrence;
                })}
                getOptionValue={(option) => option}
                getOptionLabel={(option) => option}
                options={RECURRENCE}
              />
              {recurrenceState === "has-danger" && (
                <label className="error">Required.</label>
              )}
            </FormGroup>
          </Col>
        </Row>
        {recurrence === "Recurring" && (
          <Row>
            <Col>
              <FormGroup
                className={classnames("has-label", recurrenceTypeState, {
                  "input-group-focus": recurrenceTypeFocus,
                })}
              >
                <label>Recurrence Type <span className="required-mark">*</span></label>
                <ReactSelect
                  onChange={(option) => handleChange(option, "recurrenceType")}
                  className="react-select react-select-primary"
                  classNamePrefix="react-select"
                  name="recurrenceType"
                  value={recurrenceType}
                  options={RECURRENCE_TYPES}
                />

                {recurrenceTypeState === "has-danger" && (
                  <label className="error">Required.</label>
                )}
              </FormGroup>
            </Col>
          </Row>
        )}
        {recurrence === "Recurring" &&
          recurrenceType &&
          (recurrenceType.value === 1 || recurrenceType.value === 2) && (
            <Row>
              <Col md="12">
                <FormGroup check>
                  <label className="mr-3">Week days <span className="required-mark">*</span></label>
                  {allWeekDays?.map((week) => (
                    <Label check className="mr-3" key={week.value}>
                      <Input
                        defaultChecked={week.isChecked}
                        onChange={handleCheckboxs}
                        type="checkbox"
                        name="weekdays"
                        value={week.value}
                      />
                      <span className="form-check-sign" />
                      {week.label}
                    </Label>
                  ))}
                </FormGroup>
                <div className="mb-3">
                  {weekdaysState === "has-danger" && (
                    <label className="error">Required.</label>
                  )}
                </div>
              </Col>
            </Row>
          )}
        <Row>
          <Col md={6} className="rdtPickerup">
            <FormGroup
              className={classnames("has-label datetime-formgroup", startDateState, {
                "input-group-focus": startDateFocus,
              })}
            >
              <label>Start <span className="required-mark">*</span></label>
              <KeyboardDateTimePicker
                variant="inline"
                value={startDate !== "" ? startDate : null}
                onChange={(value) => OnChangeOnStartDateTime(value)}
                name="startDate"
                format="MM/dd/yyyy hh:mm a"
                inputProps={{
                  className: "form-control datetimepicker",
                  placeholder: "Select...",
                }}
                onClose={() => this.props.setCallRecurringDatesAPI(true)}
              />

              {startDateError && (
                <label className="error">{startDateError}</label>
              )}
            </FormGroup>
          </Col>

          <Col md={6} className="rdtPickerup">
            <FormGroup
              className={classnames("has-label datetime-formgroup", endDateState, {
                "input-group-focus": endDateFocus,
              })}
            >
              <label>End <span className="required-mark">*</span></label>
              <KeyboardDateTimePicker
                variant="inline"
                value={endDate !== "" ? endDate : null}
                onChange={(value) => OnChangeOnEndDateTime(value)}
                // disablePast={!this.state.isEditing}
                name="endDate"
                format="MM/dd/yyyy hh:mm a"
                inputProps={{
                  className: "form-control datetimepicker",
                  placeholder: "Select...",
                }}
                onClose={() => this.props.setCallRecurringDatesAPI(true)}
              />

              {endDateError && <label className="error">{endDateError}</label>}
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Step2;
