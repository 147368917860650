import React, { useState } from "react";
import Swal from "sweetalert2";
import { Formik, Form as FormikForm, Field } from "formik";
// reactstrap components
import { FormGroup, Row, Col, Button, Spinner, CardTitle } from "reactstrap";
import { initialValues, validationSchema } from "./validation";
import {
    CustomInput,
    Select,
    DatePicker,
} from "Components/Common/Form/elements";
import Api from "Helpers/Api";
import { withRouter } from "react-router-dom";
import { GRADE_TYPES } from "Helpers/constants";
import { COUNTRIES } from "Helpers/constants";
import { SCHOOL_TYPES } from "Helpers/constants";
import ImageUpload from "Components/Common/Form/upload";
import { Input } from "Components/Common/Form/elements";

const EducationInfo = ({ profileDetails, setActiveTab, usStates, getProfileDetails, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (formValues) => {
        setIsLoading(true);
        try {
            const values = JSON.parse(JSON.stringify(formValues));
            const { id } = profileDetails;
            values.profileId = id;
            values.schoolAddress.profileId = id;
            values.grade = values.grade?.value || 0;
            values.schoolType = values.schoolType?.value || 0;
            values.schoolAddress.state = values.schoolAddress.state?.value || values.schoolAddress.state || "";
            values.educationVerification = values.educationVerification === profileDetails.userEducation.educationVerification ? "" : values.educationVerification;
            const { organizationId } = props.participants;
            const { username } = props.match.params;

            if (values.profileId === undefined)
                values.profileId = props.participants.participantId.participant.userProfile.id;

            if (username && organizationId) {
              await Api.editOrgUserEducationProfile({
                organizationId,
                UpdateEducationDto: {
                    ...values,
                },
            });
            } else await Api.editEducationProfile(values);
            getProfileDetails();
            setActiveTab("Work Experience");
            Swal.fire({
                icon: "success",
                title: "Education Profile updated successfully!",
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues(profileDetails.userEducation || { schoolAddress: {} })}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched, values, setFieldValue }) => {
                const labelClasses = (name) => errors[name] && touched[name];
                const showLicense = values.schoolType?.value >= 6;
                return (
                    <FormikForm onSubmit={handleSubmit}>
                        <Row>
                            <Col md={showLicense ? "6" : "12"}>
                                <FormGroup>
                                    <label>School Type</label>
                                    <Field
                                        component={Select}
                                        name={"schoolType"}
                                        options={SCHOOL_TYPES}
                                    />
                                </FormGroup>
                            </Col>
                            {
                                showLicense && (
                                    <Col md="6">
                                        <CustomInput
                                            label="What is the License / Certificate or Major?"
                                            value="vocationalEducation"
                                            hasError={labelClasses("vocationalEducation")}
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                        <Row>
                            <Col md="12">
                                <CustomInput
                                    label="School Name"
                                    value="schoolName"
                                    hasError={labelClasses("schoolName")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <CustomInput
                                    label="School Street Address 1"
                                    value="schoolAddress.addressLine1"
                                    hasError={labelClasses("schoolAddress.addressLine1")}
                                />
                            </Col>
                            <Col md="6">
                                <CustomInput
                                    label="School Street Address 2"
                                    value="schoolAddress.addressLine2"
                                    hasError={labelClasses("schoolAddress.addressLine2")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Country</label>
                                    <Field
                                        component={Select}
                                        name={"schoolAddress.country"}
                                        options={COUNTRIES}
                                        value={COUNTRIES.filter(function (option) {
                                            return option === values.schoolAddress.country;
                                        })}
                                        getOptionValue={(option) => option}
                                        getOptionLabel={(option) => option}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>State</label>
                                    {values.schoolAddress.country === "United States" ?
                                        <Field
                                            component={Select}
                                            name={"schoolAddress.state"}
                                            options={usStates}
                                            value={usStates.find(({ value: state }) => state === values.schoolAddress.state)}

                                        /> :
                                        <Field
                                            component={Input}
                                            name={"schoolAddress.state"}
                                            value={values.schoolAddress.state?.value || values.schoolAddress.state}
                                        />
                                    }
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <CustomInput
                                    label='Postal Code'
                                    value={"schoolAddress.postalCode"}
                                    hasError={labelClasses(
                                        "schoolAddress..postalCode"
                                    )}
                                    maxLength={6}
                                />
                            </Col>
                        </Row>
                        <div className='full-divider' />
                        <Row>
                            <Col md='12'>
                                <CardTitle tag='h4' className='title'>
                                    Contact Information
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <CustomInput
                                    label="First Name"
                                    value="schoolContactFirstName"
                                    hasError={labelClasses("schoolContactFirstName")}
                                />
                            </Col>
                            <Col md="6">
                                <CustomInput
                                    label="Last Name"
                                    value="schoolContactLastName"
                                    hasError={labelClasses("schoolContactLastName")}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col md="6">
                                <CustomInput
                                    label="Email / Work Email"
                                    value="schoolContactEmail"
                                    hasError={labelClasses("schoolContactEmail")}
                                />
                            </Col>
                            <Col md="6">
                                <CustomInput
                                    label="Phone Number"
                                    value="schoolContactPhoneNumber"
                                    hasError={labelClasses("schoolContactPhoneNumber")}
                                />
                            </Col>
                        </Row>
                        <div className='full-divider mb-3' />
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>
                                        Grade
                                    </label>
                                    <Field
                                        component={Select}
                                        name="grade"
                                        options={GRADE_TYPES}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Date of Graduation or Expected Date of Graduation</label>
                                    <Field component={DatePicker} name='dateOfGraduation' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <FormGroup className="text-center">
                                    <label>Upload Document</label>
                                    <ImageUpload
                                        defaultValue={values.educationVerification}
                                        accept='application/pdf'
                                        onChange={({ fileUrl }) =>
                                            setFieldValue("educationVerification", fileUrl)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="9">
                                <CustomInput
                                    label="Grade Point Average (GPA)"
                                    value="gradePointAvarage"
                                    hasError={labelClasses("gradePointAvarage")}
                                />
                                <FormGroup>
                                    <label>Enter Date of Issue or Expiration (Whichever is listed on document)</label>
                                    <Field component={DatePicker} name='dateOfIssue' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <CustomInput
                                    label="Educational Awards or Honors Received"
                                    value="educationAwards"
                                />
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Educational Awards or Honors Received Date</label>
                                    <Field component={DatePicker} name='educationAwardsDate' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button
                            className="btn-fill float-right"
                            color="primary"
                            type="submit"
                        >
                            Save {isLoading && <Spinner size="sm" className="ml-2" />}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default withRouter(EducationInfo);
