import React, { useState } from "react";
import Swal from "sweetalert2";
// reactstrap components
import { FormGroup, Row, Col, Button, CardTitle, Spinner } from "reactstrap";
import { initialValues, validationSchema } from "./validation";
import { Formik, Form as FormikForm, Field, FieldArray } from "formik";
import { Select, Input } from "Components/Common/Form/elements";
import { errorMessage } from "Helpers/Validation";
import Api from "Helpers/Api";
import { withRouter } from "react-router-dom";
import CustomSelect from "../../../Common/Form/CustomSelect";
import {
    visionOptions,
    hearingOptions,
    medicalConditionsOptions
} from "./optionsData";

const MedicalInfo = ({ profileDetails, relationship, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const handleSubmit = async (values) => {
        values.updateEmergencyContactsDto.forEach(val => {
            val.profileId = profileDetails.id
        });
        
        const getSelectValues = (select) => select ? select.map(({value}) => value).toString() : "";

        const updateHealthDto = {
            ...values.updateHealthDto
        }

        updateHealthDto.vision = getSelectValues(updateHealthDto.vision);
        updateHealthDto.hearing = getSelectValues(updateHealthDto.hearing);
        updateHealthDto.medicalConditions = getSelectValues(updateHealthDto.medicalConditions);
        const payload = { ...values, updateHealthDto };
        const { organizationId } = props.participants;
        const { username } = props.match.params;
        setIsLoading(true);
        try {
         if(username && organizationId){
          await Api.editOrgUserHealthProfile(payload);
         }
         else {
          await Api.editHealthProfile(payload);
      } Swal.fire({
        icon: "success",
        title: "Health Profile updated successfully!",
    });
        } catch (error) {
    console.error(error);
          } finally {
        setIsLoading(false);
            }   
    };

    return (
        <Formik
            initialValues={initialValues(profileDetails)}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, errors, touched, values }) => {
                const labelClasses = (name) => errors[name] && touched[name];

                return (
                    <FormikForm onSubmit={handleSubmit}>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Vision</label>
                                    <Field
                                        name="updateHealthDto.vision"
                                        options={visionOptions}
                                        component={Select}
                                        isMulti
                                        placeholder="Select a vision..."
                                    />
                                    {errorMessage('updateHealthDto.vision')}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <label>Hearing</label>
                                    <Field
                                        name="updateHealthDto.hearing"
                                        options={hearingOptions}
                                        component={Select}
                                        isMulti
                                        placeholder="Select a hearing..."
                                    />
                                    {errorMessage('updateHealthDto.hearing')}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <label>Medical Conditions</label>
                                    <Field
                                        name="updateHealthDto.medicalConditions"
                                        options={medicalConditionsOptions}
                                        component={Select}
                                        isMulti
                                        placeholder="Select a Medical Conditions..."
                                    />
                                    {errorMessage('updateHealthDto.medicalConditions')}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Health Issues</label>
                                    <Field component={Input} type="textarea" name="updateHealthDto.healthIssues" />
                                    {errorMessage("updateHealthDto.healthIssues")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='full-divider' />
                        <Row>
                            <Col md='12'>
                                <CardTitle tag='h4' className='title'>
                                    Doctor / Medical Center Information
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <FormGroup>
                                    <label>Medical Center Name</label>
                                    <Field component={Input} type="text" name="updateHealthDto.medicalCenterName" />
                                    {errorMessage("updateHealthDto.medicalCenterName")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Address Line 1</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.addressLine1" />
                                    {errorMessage("updateHealthDto.doctorsAddress.addressLine1")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Address Line 2</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.addressLine2" />
                                    {errorMessage("updateHealthDto.doctorsAddress.addressLine2")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Country</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.country" />
                                    {errorMessage("updateHealthDto.doctorsAddress.country")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>City</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.city" />
                                    {errorMessage("updateHealthDto.doctorsAddress.city")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>State</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.state" />
                                    {errorMessage("updateHealthDto.doctorsAddress.state")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Postal Code</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsAddress.postalCode" />
                                    {errorMessage("updateHealthDto.doctorsAddress.postalCode")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Doctors First Name</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsFirstName" />
                                    {errorMessage("updateHealthDto.doctorsFirstName")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Doctors Last Name</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsLastName" />
                                    {errorMessage("updateHealthDto.doctorsLastName")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Doctors Phone Number</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsPhone" />
                                    {errorMessage("updateHealthDto.doctorsPhone")}
                                </FormGroup>
                            </Col>
                            <Col md='12'>
                                <FormGroup>
                                    <label>Doctor Email</label>
                                    <Field component={Input} type="text" name="updateHealthDto.doctorsEmail" />
                                    {errorMessage("updateHealthDto.doctorsEmail")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='full-divider' />
                        <Row>
                            <Col md='12'>
                                <CardTitle tag='h4' className='title'>
                                    Health Insurance Information
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Health Insurance Name</label>
                                    <Field component={Input} type="text" name="updateHealthDto.healthInsuranceName" />
                                    {errorMessage("updateHealthDto.healthInsuranceName")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Health Insurance Phone</label>
                                    <Field component={Input} type="text" name="updateHealthDto.healthInsurancePhone" />
                                    {errorMessage("updateHealthDto.healthInsurancePhone")}
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Insurance ID or Policy #</label>
                                    <Field component={Input} type="text" name="updateHealthDto.insuranceId" />
                                    {errorMessage("updateHealthDto.insuranceId")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="full-divider" />
                        <Row>
                            <Col md="12">
                                <CardTitle tag="h4" className="title">
                                    Emergency Contact
                                </CardTitle>
                            </Col>
                        </Row>
                        <FieldArray name="guardians">
                            <div>
                                <Row>
                                    <Col md="12">
                                        <CardTitle tag="h5">
                                            <span className="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                                </svg>
                                            </span>
                                            Contact 1
                                        </CardTitle>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-4">
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.0.firstName`
                                            )}
                                        >
                                            <label>First Name</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.0.firstName`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.0.firstName`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.0.lastName`
                                            )}
                                        >
                                            <label>Last Name</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.0.lastName`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.0.lastName`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.0.number`
                                            )}
                                        >
                                            <label>Phone Number</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.0.number`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.0.number`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.0.relationship`
                                            )}
                                        >
                                            <label>Relationship</label>
                                            <Field
                                                name={`updateEmergencyContactsDto.0.relationship`}
                                                options={relationship.map(v => {
                                                    return {
                                                        value: v.id,
                                                        label: v.displayName
                                                    }
                                                })}
                                                component={CustomSelect}
                                                placeholder="Select a relationship..."
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.0.relationship`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.0.email`
                                            )}
                                        >
                                            <label> Email</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.0.email`}
                                                type="email"
                                                maxLength={50}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.0.email`)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CardTitle tag="h5">
                                            <span className="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                                </svg>
                                            </span>
                                            Contact 2
                                        </CardTitle>
                                    </Col>
                                </Row>
                                <Row className="mt-3 mb-4">
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.1.firstName`
                                            )}
                                        >
                                            <label>First Name</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.1.firstName`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.1.firstName`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.1.lastName`
                                            )}
                                        >
                                            <label>Last Name</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.1.lastName`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.1.lastName`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.1.number`
                                            )}
                                        >
                                            <label>Phone Number</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.1.number`}
                                                type="text"
                                                maxLength={15}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.1.number`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.1.relationship`
                                            )}
                                        >
                                            <label>Relationship</label>
                                            <Field
                                                name={`updateEmergencyContactsDto.1.relationship`}
                                                options={relationship.map(v => {
                                                    return {
                                                        value: v.id,
                                                        label: v.displayName
                                                    }
                                                })}
                                                component={CustomSelect}
                                                placeholder="Select a relationship..."
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.1.relationship`)}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup
                                            className={labelClasses(
                                            `updateEmergencyContactsDto.1.email`
                                            )}
                                        >
                                            <label> Email</label>
                                            <Field
                                                component={Input}
                                                name={`updateEmergencyContactsDto.1.email`}
                                                type="email"
                                                maxLength={50}
                                            />
                                            {errorMessage(`updateEmergencyContactsDto.1.email`)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </FieldArray>
                        <Button
                            className="btn-fill float-right"
                            color="primary"
                            type="submit"
                        >
                            Save {isLoading && <Spinner size="sm" className="ml-2" />}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default withRouter(MedicalInfo);
