import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
// reactstrap components
import { 
  Button,
  Modal,
  ModalBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { encodeId } from "Helpers/utils";
import { validationSchema, initialValues } from "./validation";
import {
  getInputClass,
  getLabelClass,
  getBorderStyle,
} from "Components/Common/Form";
import { Select } from "Components/Common/Form/elements";
import { errorMessage } from "Helpers/Validation";
import Api from "Helpers/Api";

const AddParticipantModal = (props) => {

  const [inputFocus, setInputFocus] = useState("");
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const getOrganizationUsers = async () => {
    try {
      const result = await Api.getOrganizationUsers(props.orgId);
      setOrganizationUsers(result);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getOrganizationUsers();
  }, [props.orgId]);

  const onSubmit = async (values, actions) => {
    // check the on submit of the registration.
    try {
      const result = await Api.attachParticipant({
        organizationUserId: values.organizationUser.id,
        participantId: props.userInfo.userId
      });
  
      Swal.fire({
        icon: "success",
        title: "Congrats! user has been assigned successfully.",
      }).then(() => {
        props.history.push(`/organization/${encodeId(props.orgId)}/participants`);
      });
    } catch (error) {
      const { errors } = error.response.data;
      if (errors.length > 0)
        Swal.fire({
          icon: "error",
          title: errors[0].description,
        });
      console.error("onSubmit -> error", error);
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <>
      <Modal
        className="add_participants_popup_modal"
        toggle={() => props.handleAddParticipantPopupToggle()}
        isOpen={props.modalOpen}
      >
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Add participant to organization
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => props.handleAddParticipantPopupToggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
          {({
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
              values,
            }) => {
              const inputClass = (name) =>
                getInputClass(errors, touched, inputFocus, name);
              const labelClass = (name) =>
                getLabelClass(errors, touched, name);
              const borderStyle = (name) =>
                getBorderStyle(errors, touched, inputFocus, name);
              return (
                <Form
                  className="form"
                  onSubmit={handleSubmit}
                  id="addParticipantValidation"
                >
                  <Row>
                    <Col sm="12">
                      <FormGroup
                        className={labelClass("organizationUser")}
                      >
                        <label className="labels">
                          Assign To
                        </label>
                        <InputGroup
                          className={inputClass("organizationUser")}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              style={borderStyle("organizationUser")}
                            >
                              <i className="tim-icons icon-alert-circle-exc" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            component={Select}
                            className="react-select info form-control"
                            classNamePrefix="react-select"
                            getOptionLabel={(option) =>
                              option.user.name
                            }
                            getOptionValue={(option) => option.id}
                            name="organizationUser"
                            options={organizationUsers}
                            onFocus={() =>
                              setInputFocus("organizationUser")
                            }
                            onBlur={() => setInputFocus("")}
                            // value={values.organizationUser}
                          />
                        </InputGroup>
                        {errorMessage("organizationUser")}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row> 
                    <Col sm="12">
                      <Button
                        type="submit"
                        className="btn-round"
                        color="info"
                        size="lg"
                      >
                        Add Participant
                        {isSubmitting && (
                          <Spinner size="sm" className="ml-2" />
                        )}
                      </Button>
                      <Button
                        size="lg"
                        color="default ml-3"
                        className="btn-round"
                        onClick={() => props.handleAddParticipantPopupToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withRouter(AddParticipantModal);