/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "Redux/Actions/auth.action";
import { setActiveRoute } from "Redux/Actions/active-route.action";
import { URL_LOGIN } from "Helpers/urls";
import { withRouter } from "react-router-dom";


// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class LandingHeaderNavbar extends React.Component {
  constructor(props){
    super(props);
  }
  state = {
    navbarColor: "navbar-transparent"
  };
  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }
  scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
  };
  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      this.setState({
        navbarColor: "bg-info"
      });
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      this.setState({
        navbarColor: "navbar-transparent"
      });
    }
  };

  handleClick = () => {
    if(this.props.isLoggedIn){
      this.props.setActiveRoute("");
      this.props.logout();
      this.props.history.push(URL_LOGIN);
    }else{
      this.props.history.push('login')
    }
  }
  render() {
    return (
      <>
        <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg">
          <Container className="navbarContent">
            <div className="navbar-translate">
              <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              <img
                alt="..."
                className="logo-img"
                src={require("Assets/img/logo/eleveight_logo.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navigation">
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#navigation">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                        alt="..."
                        className="logo-img"
                        src={require("Assets/img/logo/eleveight_logo.png")}
                        />
                    </a>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button className="navbar-toggler" id="navigation">
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto align-items-center d-flex" navbar>
                <NavItem>
                    <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "home")}>Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "projects")}>Projects</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "contact")}>Contact</NavLink>
                </NavItem>
                <NavItem>
                  <Button
                    className="nav-link"
                    color="default"
                    size="sm"
                    onClick={this.handleClick}
                  >
                    <p>{this.props.isLoggedIn?'Logout':'Login'}</p>
                  </Button>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn:state.auth.isLoggedIn
});

export default connect(mapReduxStateToProps, {logout, setActiveRoute})(withRouter(LandingHeaderNavbar));
