/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link,withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "Redux/Actions/auth.action";
import { URL_LOGIN } from "Helpers/urls";
import { setActiveRoute } from "Redux/Actions/active-route.action";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class Footer extends React.Component {
  constructor(props){
    super(props);
  }
  scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
  };

  handleClick = () => {
    if(this.props.isLoggedIn){
      this.props.setActiveRoute("");
      this.props.logout();
      this.props.history.push(URL_LOGIN);
    }else{
      this.props.history.push('login')
    }
  }
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <Row className="align-items-center">
              <Col md="3">
                <img
                    alt="..."
                    className="logo-img"
                    src={require("Assets/img/logo/eleveight_logo.png")}
                    />
              </Col>
              <Col md="9">
                <Nav>
                    <NavItem>
                        <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "home")}>Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "projects")}>Projects</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/landing" tag={Link} onClick={e => this.scrollPage(e, "contact")}>Contact</NavLink>
                    </NavItem>
                    <NavItem>
                    <Button
                        className="nav-link"
                        color="default"
                        size="sm"
                        onClick={this.handleClick}
                    >
                        {this.props.isLoggedIn?'Logout':'Login'}
                    </Button>
                    </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn:state.auth.isLoggedIn
});

export default connect(mapReduxStateToProps, {logout, setActiveRoute})(withRouter(Footer));
