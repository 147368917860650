import * as yup from "yup";
import {
  GENERIC_REQUIRED,
} from "Helpers/Validation/messages";

const initialValues = {
  organizationUser: "",
};

const validationSchema = yup.object().shape({
  organizationUser: yup.string().required(GENERIC_REQUIRED),
});

export { validationSchema, initialValues };
