import { findItem } from "Helpers/utils";
import { phoneRegExp } from "Helpers/Validation/regex";
import * as yup from "yup";
import { SALARY_TYPES, TERMINATE_REASONS, WORK_STATUSES } from "./constants";
import { addressVerifications } from "../Basic/validation";

const initialWork = {
  employerName: "",
  employerAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    isPrimary: false
  },
  employerPhone: "",
  occupationTitle: "",
  occupationDepartment: "",
  startDate: "",
  endDate: "",
  endDatePreset: false,
  salaryType: 0,
  rate: 0,
  benefits: false,
  comments: "",
  income: 0
};

const initialValues = (details) => ({
  veteranStatus: details.veteranStatus ? details.veteranStatus + "" : 0,
  branch: details.branch || 0,
  enlistedDate: details.enlistedDate ? new Date(details.enlistedDate) : "",
  dischargeDate: details.dischargeDate ? new Date(details.dischargeDate) : "",
  honorableDischarge: details.honorableDischarge === "true" ? "true" : "false",
  honorExplain:
    details.honorableDischarge !== "true" ? details.honorableDischarge : "",
  militaryVerification: details.militaryVerification || 0,
  issuedDate: details.issuedDate ? new Date(details.issuedDate) : "",
  militaryDocumentData: details.militaryDocumentData || "",
  status: findItem(details.status, WORK_STATUSES),
  terminatedReason: findItem(details.terminatedReason, TERMINATE_REASONS),
  terminationDate: details.terminationDate
    ? new Date(details.terminationDate)
    : "",
  workExperienceData: !details.workExperienceData?.length ? [initialWork] : details.workExperienceData.map(obj => {
    const copy = { ...obj };

    delete copy.addressVerificationDocument;
    delete copy.documentExpirationDate;
    
    copy.endDate = !copy.endDate ? copy.endDate : new Date(copy.endDate);
    copy.startDate = !copy.startDate ? "" : new Date(copy.startDate);
    copy.endDatePreset = copy.endDate === null;
    copy.salaryType = !copy.salaryType ? 0 : SALARY_TYPES.find(sal => sal.value === copy.salaryType)

    for (const key in copy) {
      copy[key] = copy[key] === null ? "" : copy[key]
    }

    return {...copy};
  }),
  desiredOccupation: details.desiredOccupation || "",
  deletedDate: "2022-12-15T17:26:10.597Z",
  updateDate: "2022-12-15T17:26:10.597Z",
});

const validationSchema = yup.object().shape({
  workExperienceData: yup.array().of(
    yup.object().shape({
      employerPhone: yup
        .string()
        .max(15)
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
      employerAddress: addressVerifications,
      endDate: yup.date().when(
        ['startDate'],
        {
          is: (startDate) => !!startDate,
          then: (startDate) => {
            return yup.date().min(yup.ref("startDate"), "End date can't be before start date");
          },
        }
      )
      .nullable(true)
      .transform((o, val) => !val ? null : o) 
    })
  )    
});

export { initialValues, validationSchema, initialWork };
