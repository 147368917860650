
const ETHNICITIES = [
  { label: "African American / Black", value: 1 },
  { label: "Asian", value: 2 },
  { label: "Caucasian / White", value: 3 },
  { label: "Hispanic / Latino", value: 4 },
  { label: "Middle Eastern", value: 5 },
  { label: "Native American / Alaskan", value: 6 },
  { label: "Pacific Islander / Hawaiian", value: 7 },
  { label: "Other", value: 8 }
];

const PRIMARY_LANGUAGES = [
  {
    label: "English",
    value: 1
  },
  {
    label: "Arabic",
    value: 2
  },
  {
    label: "Armenian",
    value: 3
  },
  {
    label: "Chinese (Cantonese)",
    value: 4
  },
  {
    label: "Chinese (Mandarin)",
    value: 5
  },
  {
    label: "Japanese",
    value: 6
  },
  {
    label: "Korean",
    value: 7
  },
  {
    label: "Lao",
    value: 8
  },
  {
    label: "Persian",
    value: 9
  },
  {
    label: "Spanish",
    value: 10
  },
  {
    label: "Filipino",
    value: 11
  },
  {
    label: "Russian",
    value: 12
  },
  {
    label: "Vietnamese",
    value: 13
  },
  {
    label: "Other",
    value: 14
  }
];

const SEXUAL_ORIENTATION = [
  { label: 'Heterosexual', value: 1 },
  { label: 'Gay', value: 2 },
  { label: 'Lesbian', value: 3 },
  { label: 'Bisexual', value: 4 },
  { label: 'Decline to answer', value: 5 },
  { label: 'Other', value: 6 },
];

const UNDERSTAND_ENGLISH = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 2 },
  { label: 'Needs and Interpreter', value: 3 },
  { label: 'Other', value: 5 },
];

const MARTIAL_STATUS_TYPES = [
  { label: 'Single', value: 1 },
  { label: 'Married', value: 2 },
  { label: 'Separated', value: 3 },
  { label: 'Divorced', value: 4 },
  { label: "Remaried", value: 5 },
  { label: 'Widowed / Deceased Partner', value: 6 },
  { label: 'Other', value: 7 },
];

const INCOME_VERIFICATION_TYPES = [
  'Payroll Stub',
  'Tax Return',
  'Unemployment Benfits',
  "Veteran's Benefits",
  'Public Benefits',
  'Rental Assistance',
  "Placed in Foster Care",
  'Self-Certification Letter',
]

export {
  ETHNICITIES,
  PRIMARY_LANGUAGES,
  SEXUAL_ORIENTATION,
  UNDERSTAND_ENGLISH,
  MARTIAL_STATUS_TYPES,
  INCOME_VERIFICATION_TYPES
}