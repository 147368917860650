import React from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import classnames from "classnames";
import {
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col
} from "reactstrap";
import { Input } from "Components/Common/Form/elements";
import moment from 'moment';

const ActivityDetails = (props) => {
    var {
        toggle,
        data: { name, description, siteName, startDate, startTime, endDate, endTime, isOpen },
    } = props;
    const modalStyles = {
        backgroundColor: 'rgb(35 39 98)',
        color: 'white',
        fontSize: '.75rem'
    };
    const modalHeader = {
        color: 'white',
        fontSize: '15px'
    }
    return (

        <Modal isOpen={isOpen} toggle={toggle} >
            <div style={modalStyles}>
                <ModalHeader toggle={toggle}>
                    <div style={modalHeader}>
                        Project Activity Date from {name} ({moment(startDate).format('DD MMM, YYYY')})
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Formik

                        >
                            {({ errors, touched }) => {
                                const labelClasses = (name) =>
                                    classnames("has-label", {
                                        "has-danger": errors[name] && touched[name],
                                    });
                                return (

                                    <FormikForm >
                                        <Col md='12'>
                                            <FormGroup className={labelClasses("name")}>
                                                <label>Name</label>
                                                <Field
                                                    type="text"
                                                    component={Input}
                                                    name="name"
                                                    value={name}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md='12'>
                                            <FormGroup className={labelClasses("Description")}>
                                                <label>Decription</label>
                                                <Field
                                                    type="text"
                                                    component={Input}
                                                    name="description"
                                                    value={description}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='12'>
                                            <FormGroup className={labelClasses("")}>
                                                <label>Site Name</label>
                                                <Field
                                                    type="text"
                                                    component={Input}
                                                    value={siteName}
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='12'>
                                            <Col md={6} className='row'>
                                                <FormGroup className={labelClasses("")}>
                                                    <label>Start Date</label>
                                                    <Field
                                                        type="text"
                                                        component={Input}
                                                        value={startDate}
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className={labelClasses("")}>
                                                    <label>Start Time</label>
                                                    <Field
                                                        type="text"
                                                        component={Input}
                                                        value={startTime}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Col>
                                        <Col md='12'>
                                            <Col md={6} className='row'>
                                                <FormGroup className={labelClasses("")}>
                                                    <label>End Date</label>
                                                    <Field
                                                        type="text"
                                                        component={Input}
                                                        value={endDate}
                                                        disabled={true}

                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className={labelClasses("")}>
                                                    <label>End Time</label>
                                                    <Field
                                                        type="text"
                                                        component={Input}
                                                        value={endTime}
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Col>


                                    </FormikForm>


                                );
                            }}
                        </Formik>
                    </Row>
                </ModalBody>
            </div>

        </Modal>
    );
};

export default ActivityDetails;
