import * as yup from 'yup';
import {
    GENERIC_REQUIRED,
    EMAIL_INVALID,
    BIRTHDAY_AGE_RESTRICTION
} from 'Helpers/Validation/messages';
import moment from 'moment';
import { phoneRegExp } from 'Helpers/Validation/regex';
import { ADDRESS_VERIFICATION_TYPES } from 'Helpers/constants';
import { BIRTH_VERIFICATION_TYPES } from 'Helpers/constants';

const defaultAddress = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
};

const addresses = [
    { field: 'userHomeAddress', title: 'Home Address' },
    { field: 'userMailingAddress', title: 'Mail Address' }
];

const initialValues = (details) => {
    const data = {
        firstName: details.firstName || '',
        lastName: details.lastName || '',
        userName: details.userName || '',
        middleName: details.middleName || '',
        suffix: details.suffix || '',
        age: moment().diff(moment(details.dateOfBirth), 'years'),
        dateOfBirth: details.dateOfBirth ? new Date(details.dateOfBirth) : '',
        dateOfIssue: details.dateOfIssue ? new Date(details.dateOfIssue) : '',
        primaryPhone: details.primaryPhone || '',
        secondPhone: details.secondPhone || '',
        dateOfBirthVerification: details.dateOfBirthVerification ? BIRTH_VERIFICATION_TYPES.find(obj => obj.value === details.dateOfBirthVerification) : 0,
        dateOfBirthDocumentData: details.dateOfBirthDocumentData || '',
        personalEmail: details.personalEmail || '',
        workEmail: details.workEmail || '',
        bio: details.bio || '',
        isHomeless: details.isHomeless ?? false,
        imageData: details.avatar || '',
        fileName: details.fileName || '',
        homeAddressVerification: details.homeAddressVerification ? ADDRESS_VERIFICATION_TYPES.find(obj => obj.value === details.homeAddressVerification) : 0,
        homeAddressIssuedDate: details.homeAddressIssuedDate ? new Date(details.homeAddressIssuedDate) : "",
        homeAddressDocumentData: details.homeAddressDocumentData || "",
        differentHomeAddress: true,
    }

    addresses.forEach(({ field }) => {
        if (details[field]) {
            data[field] = {
                addressLine1: details[field].addressLine1 || '',
                addressLine2: details[field].addressLine2 || '',
                city: details[field].city || '',
                state: details[field].state || '',
                country: details[field].country || '',
                postalCode: details[field].postalCode || '',
            }
        }
        else {
            data[field] = defaultAddress
        }

    });

    return data;
};

export const addressVerifications =
    yup.object().shape({
        addressLine1: yup
            .string()
            .max(250, 'Address Line 1 can not be more than 250 characters.'),
        addressLine2: yup
            .string()
            .max(100, 'Address Line 2 can not be more than 100 characters.'),
        city: yup.string().max(150, 'City can not be more than 150 characters.'),
        postalCode: yup
            .string()
            .max(15, 'Postal code can not be more than 15 characters.')
            .optional(),
    });

const validationSchema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(GENERIC_REQUIRED)
        .max(80, 'First Name can not be more than 250 characters.'),
    lastName: yup
        .string()
        .trim()
        .required(GENERIC_REQUIRED)
        .max(80, 'Last Name can not be more than 250 characters.'),
    personalEmail: yup
        .string()
        .email(EMAIL_INVALID)
        .required(GENERIC_REQUIRED)
        .max(150, 'Personal email can not be more than 150 characters.'),
    workEmail: yup
        .string()
        .email(EMAIL_INVALID)
        .optional()
        .max(150, 'Work email can not be more than 150 characters.'),
    dateOfBirth: yup
        .date()
        .test('Age Restriction', BIRTHDAY_AGE_RESTRICTION, (value) =>
            value ? moment().diff(moment(value), 'years') >= 13 : true
        )
        .required(GENERIC_REQUIRED),
    primaryPhone: yup
        .string()
        .max(15)
        .matches(phoneRegExp, 'Phone number is not valid')
        .optional(),
    secondPhone: yup
        .string()
        .max(15)
        .matches(phoneRegExp, 'Phone number is not valid')
        .optional(),
    bio: yup
        .string()
        .trim()
        .max(150, 'Only 150 characters allowed!')
        .optional(),
    userHomeAddressDto: addressVerifications,
    userMailingAddressDto: addressVerifications
});

export { initialValues, validationSchema, addresses };
