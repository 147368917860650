import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Api from 'Helpers/Api';
import Table from '../../../../Common/Table';
import { USER_ROLES } from 'Helpers/constants';
import { hasRoles } from 'Helpers/utils';
import moment from 'moment';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody
} from "reactstrap";

const DashboardActivityDates = (props) => {

    const [activityDates, setActivityDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionsVisibility, setActionsVisibility] = useState({});
    const { user } = props;
    const formatDate = (format = 'MMMM DD, YYYY', value) => moment(value).format(format);

    const getActivityDates = async () => {
        setLoading(true);
        try {
            const result = await Api.getDashboardActivitydates();

            let projctActivityDates = result
                .map((dates) => {
                    return {
                        ...dates,
                        activityDate: formatDate(
                            'MMMM DD, YYYY',
                            dates.activityDate
                        ),
                    };
                });

            setActivityDates(projctActivityDates);
        } catch (error) {
            console.error('getDashboardActivitydates -> error', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setActionsVisibility({
            isEditHidden: hasRoles(user, [USER_ROLES.ADMIN]),
            isDetailsHidden: hasRoles(user, [USER_ROLES.ADMIN]),
            isDeleteHidden: !hasRoles(user, [USER_ROLES.ADMIN]),
            isUpdateActivitydatesHidden: false,
        });

        getActivityDates();
    }, []);


    return (
        <div>
            {loading ? (
                <Card>
                    <CardHeader>
                        <div
                            style={{
                                width: '100%',
                                height: '500px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            Loading...
                        </div>
                    </CardHeader>
                </Card>
            ) : (
                <Card className="card-tasks">
                    <CardHeader>
                        <h6 className="title d-inline">Project Activity Dates</h6>
                    </CardHeader>
                    <CardBody>
                        <div className="table-full-width table-responsive">

                            <Table
                                columns={[

                                    {
                                        Header: 'Activity Name',
                                        accessor: 'activityName',
                                        filterable: false,
                                    },
                                    {
                                        Header: 'Project Name',
                                        accessor: 'projectName',
                                        filterable: false,
                                    },
                                    {
                                        Header: 'Activity Date',
                                        accessor: 'activityDate',
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                filterable
                                dataTable={activityDates}
                                loading={loading}
                                actionsVisibility={actionsVisibility}
                                showPaginationBottom={false}
                                isUpdateActivitydatesHidden={false}
                            />
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>

    );
}

const mapReduxStateToProps = (state) => ({
    user: state.auth.user,
    userId: state.auth.userId,
});

export default connect(mapReduxStateToProps)(withRouter(DashboardActivityDates));