const BRANCH_TYPES = [
  {
    label: "Air Force",
    value: 1
  },
  {
    label: "Army",
    value: 2
  },
  {
    label: "Coast Guard",
    value: 3
  },
  {
    label: "Marine",
    value: 4
  },
  {
    label: "National Guard",
    value: 5
  },
  {
    label: "Navy",
    value: 6
  }
];


const WORK_STATUSES = [
  {
    label: "Working Full Time",
    value: 1
  },
  {
    label: "Working Part Time (less than 32 hours per week)",
    value: 2
  },
  {
    label: "Working but received Notice of Termination(Dislocated Worker) or Military Discharge",
    value: 3
  },
  {
    label: "Received Notice of Termination or Discharge",
    value: 4
  },
  {
    label: "No Longer Working",
    value: 5
  },
  {
    label: "Never Worked",
    value: 6
  },
];

const TERMINATE_REASONS = [
  {
    label: "Terminated or laid off, eligible for or exhausted UI and unlikely to return to industry or occupation",
    value: 1
  },
  {
    label: "Lost job due to permanent closure or substantial layoff at a plant, facility or enterprise",
    value: 2
  },
  {
    label: "Was self-employed and now not working because of economic conditions or natural disaster",
    value: 3
  },
  {
    label: "Displaced homemaker",
    value: 4
  },
  {
    label: "Spouse of a member of Armed Forces who lost job due to permanent change in duty station or is No Longer Working, Working Part Time and needs additional hours or has difficulty finding or upgrading work status",
    value: 5
  },
  {
    label: "Other",
    value: 6
  },
];

const SALARY_TYPES = [
  {
    label: "Hourly rate $",
    value: 1
  },
  {
    label: "Monthly Salary $",
    value: 2
  },
  {
    label: "Estimated Annual Income",
    value: 3
  },
  {
    label: "Intern",
    value: 4
  },
  {
    label: "Volunteer",
    value: 5
  },
];

export {
  BRANCH_TYPES,
  WORK_STATUSES,
  TERMINATE_REASONS,
  SALARY_TYPES
};