import React, { useState } from "react";
import Swal from "sweetalert2";
// reactstrap components
import { FormGroup, Row, Col, Button, Spinner, CardTitle } from "reactstrap";
import { initialValues, validationSchema, initialWork } from "./validation";
import { Formik, Form as FormikForm, Field, FieldArray } from "formik";
import { errorMessage } from "Helpers/Validation";
import { RadioGroup, CustomInput } from "Components/Common/Form/elements";
import Api from "Helpers/Api";
import { withRouter } from "react-router-dom";
import { BRANCH_TYPES, SALARY_TYPES, TERMINATE_REASONS, WORK_STATUSES } from "./constants";
import { DatePicker } from "Components/Common/Form/elements";
import moment from "moment";
import { Input } from "Components/Common/Form/elements";
import ImageUpload from "Components/Common/Form/upload";
import { Select } from "Components/Common/Form/elements";
import { COUNTRIES } from "Helpers/constants";
import classnames from 'classnames';

const EmploymentInfo = ({ profileDetails, setActiveTab, usStates, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const apiPayload = (values) => {
        const payload = {
            ...values,
            veteranStatus: values.veteranStatus ? parseInt(values.veteranStatus) : 0,
            branch: values.branch ? parseInt(values.branch) : 0,
            militaryVerification: values.militaryVerification ? parseInt(values.militaryVerification) : 0,
            militaryDocumentData: values.militaryDocumentData === profileDetails.userEmployment.militaryDocumentData ? "" : values.militaryDocumentData,
            status: values.status?.value || 0,
            terminatedReason: values.terminatedReason?.value || 0,
            honorableDischarge: values.honorableDischarge === "false" ? values.honorExplain : "true",
            workExperienceData: values.workExperienceData.map(({ employerAddress: { id, ...employerAddress }, ...el }) => {
                return {
                    ...el,
                    employerAddress: {
                        ...employerAddress,
                        state: employerAddress.state?.value || employerAddress.state || ""
                    },
                    endDate: el.endDatePreset ? null : !el.endDate ? "" : moment(el.endDate).format('YYYY-MM-DD'),
                    startDate: !el.startDate ? "" : moment(el.startDate).format('YYYY-MM-DD'),
                    salaryType: el.salaryType?.value || 0,
                    benefits: el.benefits === "true" || el.benefits,
                }
            })
        };
        delete payload.honorExplain;
        return payload;
    }

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const { id } = profileDetails;
            values.profileId = id;

            const { organizationId } = props.participants;
            const { username } = props.match.params;

            if (username && organizationId) {
                if (values.profileId === undefined)
                    values.profileId = props.participants.participantId.participant.userProfile.id;
                    values = apiPayload(values);
                await Api.editOrgUserEmploymentProfile({
                    organizationId,
                    updateEmploymentDto: {
                        ...values,
                    },
                });
            } else {
                values = apiPayload(values);
                await Api.editEmploymentProfile(values);
            }
            setActiveTab("Health");
            Swal.fire({
                icon: "success",
                title: "Employment Profile updated successfully!",
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues(profileDetails.userEmployment || {})}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => {
                const labelClasses = (name, i, field) => {
                    if (i !== undefined && field) {
                        return errors[name] && errors[name][i] && errors[name][i][field]
                            && touched[name] && touched[name][i] && touched[name][i][field];
                    }
                    return errors[name] && touched[name];
                };

                return (
                    <FormikForm onSubmit={handleSubmit}>
                        <Row>
                            <Col md="5">
                                <FormGroup>
                                    <label>Military Veteran</label>
                                    <Field
                                        component={RadioGroup}
                                        options={[
                                            { label: "Yes", value: "1" },
                                            { label: "No", value: "2" },
                                            { label: "Active Military", value: "3" },
                                        ]}
                                        name="veteranStatus"
                                    />
                                </FormGroup>
                            </Col>
                            {
                                (values.veteranStatus === "1" || values.veteranStatus === "3") &&
                                <>
                                    <Col md="7">
                                        <FormGroup>
                                            <label>Branch</label>
                                            <Field
                                                component={RadioGroup}
                                                name="branch"
                                                options={BRANCH_TYPES}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Date Enlisted</label>
                                            <Field
                                                component={DatePicker}
                                                name="enlistedDate"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {values.veteranStatus !== "3"
                                        ?
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Discharge Date</label>
                                                <Field
                                                    key={values.veteranStatus}
                                                    component={DatePicker}
                                                    name="dischargeDate"
                                                    defaultValue={values.veteranStatus === "3" ? new Date(moment()) : undefined}
                                                />
                                            </FormGroup>
                                        </Col>
                                        : null
                                    }
                                    <Col md="6">
                                        <FormGroup>
                                            <label>If Veteran, Honorable Discharge?</label>
                                            <Field
                                                component={RadioGroup}
                                                name="honorableDischarge"
                                                options={[
                                                    { label: "Yes", value: "true" },
                                                    { label: "Other", value: "false" }
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        values.honorableDischarge === "false" &&
                                        <Col md="8">
                                            <FormGroup>
                                                <label>Explain</label>
                                                <Field
                                                    component={Input}
                                                    name="honorExplain"
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col md="6">
                                        <FormGroup>
                                            <label>Veteran or Active Miliary Verification</label>
                                            <Field
                                                component={RadioGroup}
                                                name="militaryVerification"
                                                options={[
                                                    { label: "CAC", value: 1 },
                                                    { label: "DD-214", value: 2 },
                                                    { label: "Other", value: 3 }
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className='text-center'>
                                            <label className='text-center'>UPLOAD Document</label>
                                            <ImageUpload
                                                defaultValue={values.militaryDocumentData}
                                                accept='application/pdf'
                                                onChange={({ fileUrl }) =>
                                                    setFieldValue("militaryDocumentData", fileUrl)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label>
                                                Enter Date of Issue or Expiration (Whichever is listed on document)
                                            </label>
                                            <Field
                                                name="issuedDate"
                                                component={DatePicker}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            }

                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Work Status</label>
                                    <Field
                                        component={Select}
                                        options={WORK_STATUSES}
                                        name="status"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <CustomInput
                                        label="Occupation(s) desired"
                                        value="desiredOccupation"
                                        hasError={labelClasses("desiredOccupation")}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.status.value === 3 &&
                            <Row>
                                <Col md="7">
                                    <FormGroup>
                                        <label>Date of Unemployment or Expected Date of Unemployment</label>
                                        <Field
                                            component={DatePicker}
                                            name="terminationDate"
                                        />
                                    </FormGroup>
                                </Col>
                                {
                                    values.terminationDate &&
                                    <Col md="5">
                                        <FormGroup>
                                            <label>Number of weeks since Last Day of Work</label>
                                            <Field
                                                component={Input}
                                                disabled
                                                value={moment().diff(values.terminationDate, 'week')}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                        }
                        {
                            values.status.value !== 1 && values.status.value !== 2 &&
                            <>
                                {
                                    (values.status.value === 3
                                        || values.status.value === 4
                                        || values.status.value === 6) &&
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Registered Apprenticeship Program</label>
                                                <Field
                                                    component={Select}
                                                    name="terminatedReason"
                                                    options={TERMINATE_REASONS}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }
                        <FieldArray name="workExperienceData">
                            {({ push, remove }) =>
                                <>
                                    {values.workExperienceData.map((el, i) => {
                                        return (
                                            <div key={i} >
                                                {i !== 0 && <div className='full-divider' />}
                                                <Row className="sticky-header">
                                                    <Col md='12' className="d-flex justify-content-between align-items-center">
                                                        <CardTitle tag='h4' className='title'>
                                                            {values.workExperienceData.length > 1 && `#${i + 1}`} Work Experience
                                                        </CardTitle>
                                                        {values.workExperienceData.length > 1 &&
                                                            <button
                                                                className="bg-transparent border-0 h-min-content"
                                                                onClick={() => remove(i)}
                                                            >
                                                                <i
                                                                    className='fa fa-times -cursor-pointer text-white'
                                                                />
                                                            </button>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <CustomInput
                                                            label='Employer Name'
                                                            value={`workExperienceData.${i}.employerName`}
                                                        />
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className={
                                                            classnames('has-label', {
                                                                'has-danger': labelClasses(`workExperienceData`, i, "employerPhone"),
                                                            })}>
                                                            <label>Emergency Phone Number</label>
                                                            <Field
                                                                component={Input}
                                                                name={`workExperienceData.${i}.employerPhone`}
                                                                type='text'
                                                            />
                                                            {errorMessage(`workExperienceData.${i}.employerPhone`)}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <CustomInput
                                                            label='Address Line 1'
                                                            value={`workExperienceData.${i}.employerAddress.addressLine1`}
                                                            hasError={labelClasses(
                                                                `workExperienceData.${i}.employerAddress.addressLine1`
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <CustomInput
                                                            label='Address Line 2'
                                                            value={`workExperienceData.${i}.employerAddress.addressLine2`}
                                                            hasError={labelClasses(
                                                                `workExperienceData.${i}.employerAddress.addressLine2`
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col md='4'>
                                                        <CustomInput
                                                            label='City'
                                                            value={`workExperienceData.${i}.employerAddress.city`}
                                                            hasError={labelClasses(`workExperienceData.${i}.employerAddress.city`)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md='4'>
                                                        <label>Country</label>
                                                        <Field
                                                            component={Select}
                                                            name={`workExperienceData.${i}.employerAddress.country`}
                                                            options={COUNTRIES}
                                                            value={COUNTRIES.filter(function (option) {
                                                                return option === el.employerAddress.country;
                                                            })}
                                                            getOptionValue={(option) => option}
                                                            getOptionLabel={(option) => option}
                                                        />
                                                    </Col>
                                                    <Col md='4'>
                                                        <FormGroup>
                                                            <label>State</label>
                                                            {el.employerAddress.country === "United States" ?
                                                                <Field
                                                                    component={Select}
                                                                    name={`workExperienceData.${i}.employerAddress.state`}
                                                                    options={usStates}
                                                                    value={usStates.find(({ value: state }) => state === el.employerAddress.state)}

                                                                /> :
                                                                <Field
                                                                    component={Input}
                                                                    name={`workExperienceData.${i}.employerAddress.state`}
                                                                    value={el.employerAddress?.state?.value || el.employerAddress?.state}
                                                                />
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md='4'>
                                                        <CustomInput
                                                            label='Postal Code'
                                                            value={`workExperienceData.${i}.employerAddress.postalCode`}
                                                            hasError={labelClasses(
                                                                `workExperienceData.${i}.employerAddress.postalCode`
                                                            )}
                                                            maxLength={6}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Row>
                                                        <Col md='12'>
                                                            <CardTitle tag='h4' className='title'>
                                                                Occupation
                                                            </CardTitle>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <CustomInput
                                                                label="Occupation Title"
                                                                value={`workExperienceData.${i}.occupationTitle`}
                                                                hasError={labelClasses(`workExperienceData.${i}.occupationTitle`)}
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <CustomInput
                                                                label="Department (If applicable)"
                                                                value={`workExperienceData.${i}.occupationDepartment`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <label>Start Date</label>
                                                            <Field
                                                                name={`workExperienceData.${i}.startDate`}
                                                                component={DatePicker}
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <label>End Date</label>
                                                            {!el.endDatePreset && (
                                                                <Field
                                                                    name={`workExperienceData.${i}.endDate`}
                                                                    component={DatePicker}
                                                                    isValidDate={(currentDate) => !el.startDate ? true : currentDate.isAfter(moment(el.startDate))}
                                                                    // defaultValue={new Date(moment())}
                                                                />
                                                            )}
                                                            {errorMessage(`workExperienceData.${i}.endDate`)}
                                                            <div className="d-flex ml-4 align-items-center">
                                                                <label className="mt-2 mb-0">Present</label>
                                                                <Field
                                                                    component={Input}
                                                                    name={`workExperienceData.${i}.endDatePreset`}
                                                                    checked={el.endDatePreset}
                                                                    type="checkbox"
                                                                    onChange={(e) => {
                                                                        setFieldValue(`workExperienceData.${i}.endDatePreset`, e.target.checked);
                                                                        setFieldValue(`workExperienceData.${i}.endDate`, e.target.checked ? undefined : "");
                                                                        setFieldTouched(`workExperienceData.${i}.endDate`, true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md='12' style={{ minHeight: '88px' }} className="mt-3">
                                                            <label>Income</label>
                                                            <div className="currencyinput">
                                                                <span>$</span>
                                                                <Field
                                                                    component={Input}
                                                                    name={`workExperienceData.${i}.income`}
                                                                    type="number"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Income rate</label>
                                                                <Field
                                                                    component={Select}
                                                                    options={SALARY_TYPES}
                                                                    name={`workExperienceData.${i}.salaryType`}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>

                                                                <label>Benefits?</label>
                                                                <Field
                                                                    component={RadioGroup}
                                                                    options={[
                                                                        { label: "Yes", value: true },
                                                                        { label: "No", value: false }
                                                                    ]}
                                                                    name={`workExperienceData.${i}.benefits`}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <label>Comment</label>
                                                                <Field
                                                                    component={Input}
                                                                    name={`workExperienceData.${i}.comments`}
                                                                    type="textarea"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Col md="12 mb-4">
                                        <Button
                                            onClick={() => {
                                                push(initialWork);
                                            }}
                                            className="btn-fill float-right mb-4"
                                            color="primary"
                                            type="button"
                                        >
                                            Add Previous Work Experience
                                        </Button>
                                    </Col>
                                </>
                            }
                        </FieldArray>
                        <div className="d-flex flex-column w-100">
                            <Button
                                className="btn-fill float-right w-100"
                                color="primary"
                                type="submit"
                            >
                                Save {isLoading && <Spinner size="sm" className="ml-2" />}
                            </Button>
                        </div>
                    </FormikForm>
                );
            }}
        </Formik >
    );
};

export default withRouter(EmploymentInfo);
