import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  CardFooter,
  Button,
  Spinner
} from "reactstrap";
import classnames from "classnames";
import { Formik, Form as FormikForm, Field } from "formik";
import * as yup from "yup";
import { Input } from "Components/Common/Form/elements";
import { errorMessage } from "Helpers/Validation";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Api from "Helpers/Api";
import { GENERIC_REQUIRED } from "Helpers/Validation/messages";
import { URL_REGISTRATION } from "Helpers/urls";

const Invite = (props) => {
  const { id } = props.match.params;
  const params = new URLSearchParams(props.location.search);
  const name = params.get('name');
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().nullable().required(GENERIC_REQUIRED),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      await Api.inviteUsersToProject({
        Email: values.email,
        ProjectId: window.atob(id),
        InvitationLink: window.location.origin + URL_REGISTRATION,
      });

      Swal.fire({
        icon: "success",
        title: "Invitation has been sent successfully!",
      });
      resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    props.history.goBack();
  }

  return (
    <div className='content'>
      <Row>
        <Col xs={12} md={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, errors, touched }) => {
              const labelCasses = (name) =>
                classnames("has-label", {
                  "has-danger": errors[name] && touched[name],
                });
              return (
                <FormikForm onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4" className="float-left">
                        Invite users to {name}
                      </CardTitle>
                      <Button className="btn-link float-right mr-3" color="info" onClick={handleGoBack}>
                        <i className="tim-icons icon-minimal-left" /> Go back
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={labelCasses("email")}>
                        <label>Enter emails of users to invite (use ',' to separate multiple emails)</label>
                        <Field
                          type="textarea"
                          component={Input}
                          name="email"
                          maxLength={5000}
                          placeholder="john@gmail.com, doe123@gmail.com"
                        />
                        {errorMessage("email")}
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-right">
                      <Button color="primary" type="submit">
                        Send Invitation
                        {loading && <Spinner size="sm" className="ml-2" />}
                      </Button>
                    </CardFooter>
                  </Card>
                </FormikForm>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

const mapReduxStateToProps = (state) => ({
  userId: state.auth.userId,
});

export default connect(mapReduxStateToProps)(withRouter(Invite));
