import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { errorMessage } from 'Helpers/Validation';
// reactstrap components
import { FormGroup, Row, Col, Button, CardTitle, Spinner } from 'reactstrap';
import { Formik, Form as FormikForm, Field, FieldArray } from 'formik';
import { initialValues, validationSchema } from './validation';
import {
    RadioGroup,
    CustomInput,
    Select,
    DatePicker,
} from 'Components/Common/Form/elements';
import { AUTHORIZED_PLACE } from 'Helpers/constants';
import Api from 'Helpers/Api';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import ImageUpload from 'Components/Common/Form/upload';
import { ETHNICITIES, INCOME_VERIFICATION_TYPES, MARTIAL_STATUS_TYPES, PRIMARY_LANGUAGES, SEXUAL_ORIENTATION, UNDERSTAND_ENGLISH } from './constants';
import { BOOLEAN_TYPES } from 'Helpers/constants';
import { Input } from 'Components/Common/Form/elements';
import moment from 'moment';
import { GRADE_TYPES } from 'Helpers/constants';

let firstHalf = "";
const BackgroundInfo = ({
    profileDetails,
    relationship,
    getProfileDetails,
    setActiveTab,
    setemContact,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ssnValue, setSsnValue] = useState("");
    const [ssnType, setSsnType] = useState("password");
    const [fakeSsn, setFakeSsn] = useState("");
    const [explainedAnnual, setExplainedAnnual] = useState("");
    const [genders, setGenders] = useState([]);

    const apiPayload = (values) => {
        const { id } = profileDetails;
        let data = JSON.parse(JSON.stringify(values));
        if (data.guardians)
            data.guardians.map((guardian) => {
                delete guardian.value;
                delete guardian.label;
                delete guardian.updateDate;
                delete guardian.index;
                delete guardian.userProfileId;

                guardian.profileId = id;
                guardian.relationship = guardian.relationship ? guardian.relationship.id : 0;
                guardian.educationLevel = guardian.educationLevel?.value || 0;
                guardian.isEmergencyContact = typeof guardian.isEmergencyContact === "string" ?
                  guardian.isEmergencyContact === 'true' : guardian.isEmergencyContact;
                return guardian;
            });

            return {
            updateBackgroundDto: {
                profileId: id,
                ...data,
                ssn: ssnValue || "",
                additionalIncome: data.additionalIncome?.value || 0,
                additionalIncomeDocumentData: data.additionalIncomeDocumentData === profileDetails.userBackground.additionalIncomeVerification ? "" : data.additionalIncomeDocumentData,
                annualIncomeDocumentData: profileDetails.userBackground.incomeVerification === data.annualIncomeDocumentData ? "" : data.annualIncomeDocumentData,
                annualIncomeType: data.annualIncomeType?.value || 0,
                ethnicityIds: data.ethnicityIds ? data.ethnicityIds.map(({ value }) => value) : [],
                genderAtBirth: data.genderAtBirth ? parseInt(data.genderAtBirth?.value) : 0,
                genderIdentified: data.genderIdentified ? parseInt(data.genderIdentified?.value) : 0,
                maritalStatus: data.maritalStatus ? parseInt(data.maritalStatus) : 0,
                isDependentVeteran: data.isDependentVeteran === "true" || data.isDependentVeteran,
                isMilitarySpose: data.isMilitarySpose === "true" || data.isMilitarySpose,
                militaryDependantVerification: data.militaryDependantVerification === profileDetails.userBackground.militaryDependantVerification ? "" : data.militaryDependantVerification,
                parentsPrimaryLanguage: data.parentsPrimaryLanguage ? data.parentsPrimaryLanguage?.value : 0,
                primaryLanguage: data.primaryLanguage ? data.primaryLanguage?.value : "",
                sexualOrientation: data.sexualOrientation ? parseInt(data.sexualOrientation) : 0,
                understandsEnglish: data.understandsEnglish ? parseInt(data.understandsEnglish) : 0,
                citizenship: data.citizenship ? data.citizenship?.value : 0,
                usWorkPermitVerification: data.usWorkPermitVerification === profileDetails.userBackground.usWorkPermitVerification ? "" : data.usWorkPermitVerification
            },
            updateGuardiansDto: data.guardians,
        };
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            // Modify required fields
            const data = apiPayload(values);
            const { organizationId } = props.participants;
            const { username } = props.match.params;
            if (username && organizationId) {
                data.organizationId = organizationId;

                if (data.updateBackgroundDto.profileId === undefined)
                    data.updateBackgroundDto.profileId = props.participants.participantId.participant.userProfile.id;
                data.updateGuardiansDto.forEach((info) => {
                    if (info.profileId === undefined)
                        info.profileId = props.participants.participantId.participant.userProfile.id;
                });

                await Api.editOrgUserBackgroundProfile(data);
            } else await Api.editBackgroundProfile(data);
            getProfileDetails();
            setActiveTab('Education');
            Swal.fire({
                icon: 'success',
                title: 'Backgroud Profile updated successfully!',
            });
        } catch (error) {
            console.error(error);
            if (error.response) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeSsn = (e) => {
        const { target: { value } } = e;
        setSsnValue(prev => {
            const lastLetter = value[value.length - 1];
            if (lastLetter) {
                return prev += lastLetter;
            }
            return "";
        });
        if (value.length > 5) {
            const secondHalf = value.substring(5);
            setSsnType("text");
            if (value.length === 6 && !fakeSsn.includes("•")) {
                firstHalf = fakeSsn;
            }

            setFakeSsn("•••••" + secondHalf);
            setSsnValue(firstHalf + secondHalf);
        } else {
            firstHalf = firstHalf.slice(0, value.length);
            setFakeSsn(value || firstHalf);
            setSsnValue(!value.includes("•") ? value : firstHalf);
            setSsnType("password")
        }
    };
    
    /*
    const getEthnicity = async () => {
        try {
            const response = await Api.getEthnicity();
            const ethnicities = response.map((data) => {
                return {
                    label: data.displayName,
                    value: data.id,
                };
            });
        } catch (error) {
            console.error(error);
        }
    };*/    

    useEffect(() => {
        let unsubscribe = false;
        if(!profileDetails.userBackground) return; 
        const populateGenders = async () => {
            const response = await Api.getGenders();
    
            const records = response.model.map((data) => {
                return {
                    label: data.displayName,
                    value: data.id,
                };
            });

            profileDetails.userBackground.genderAtBirth = records.find(({value}) => value === profileDetails.userBackground.genderAtBirth)
            profileDetails.userBackground.genderIdentified = records.find(({value}) => value === profileDetails.userBackground.genderIdentified)
            if(!unsubscribe) {
                setGenders(records);
            }
        };

        populateGenders();

        return () => {
            unsubscribe = true;
        }
    }, [profileDetails]);

    useEffect(() => {
        if (profileDetails.userBackground) {
            const { ssn } = profileDetails.userBackground;
    
            if (ssn) {
                let ssnString = "";
                for (let i = 0; i < ssn.length; i++) {
                    ssnString += ssn[i];
                    handleChangeSsn({ target: { value: ssnString } })
                }
            }
            setSsnValue(ssn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileDetails.userBackground]);
   
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues(
                profileDetails.userBackground || {},
                relationship
            )}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, errors, touched, values, setFieldValue }) => {
                const labelClasses = (name) => errors[name] && touched[name];
                return (
                    <FormikForm onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>Gender Assigned at Birth</label>
                                    <Field
                                        name='genderAtBirth'
                                        component={Select}
                                        options={genders}
                                        value={values.genderAtBirth}
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                    {errorMessage('genderAtBirth')}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Gender Identification</label>
                                    <Field
                                        name='genderIdentified'
                                        component={Select}
                                        value={values.genderIdentified}
                                        options={genders}
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                    {errorMessage('genderIdentified')}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='8'>
                                <FormGroup>
                                    <label>Sexual Orientation</label>
                                    <Field
                                        component={RadioGroup}
                                        options={SEXUAL_ORIENTATION}
                                        name='sexualOrientation'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <label>Citizenship</label>
                                    <Field
                                        name='citizenship'
                                        component={Select}
                                        options={AUTHORIZED_PLACE}
                                        // value={AUTHORIZED_PLACE.find(function (option) {
                                        //     return option === values.citizenship.value;
                                        // })}
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                    {errorMessage('citizenship')}
                                </FormGroup>
                            </Col>
                        </Row>
                        {values.citizenship.label === 'U.S. Citizen' && props.match.params.username === undefined && (
                            <Row>
                                <Col md='6'>
                                    <FormGroup
                                        className={classnames("has-label", {
                                            "has-danger": labelClasses('ssn'),
                                        })}
                                    >
                                        <label>Social Security #</label>
                                        <Field
                                            component={Input}
                                            name="ssn"
                                            maxLength={9}
                                            onChange={handleChangeSsn}
                                            value={fakeSsn}
                                            type={ssnType}
                                        />
                                        {errorMessage("ssn")}
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        {(values.citizenship.label === 'U.S. Permanent Citizen' ||
                            values.citizenship.label ===
                            'Alien/Refuge Lawfully Admitted to U.S.') && (
                                <Row>
                                    <Col md='4'>
                                        <CustomInput
                                            label='USCIS#'
                                            value='uscis'
                                            hasError={labelClasses('uscis')}
                                            maxLength={9}
                                            minLength={7}
                                        />
                                    </Col>
                                    <Col md='3'>
                                        <FormGroup className="text-center">
                                            <label>Upload document</label>
                                            <ImageUpload
                                                accept='application/pdf'
                                                defaultValue={values.usWorkPermitVerification}
                                                onChange={({ fileUrl }) =>
                                                    setFieldValue("usWorkPermitVerification", fileUrl)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='5'>
                                        <FormGroup
                                            className={classnames('has-label', {
                                                'has-danger': labelClasses('dateOfIssue'),
                                            })}
                                        >
                                            <label>Enter Date of Issue or Expiration (Whichever is listed on document)</label>
                                            <Field component={DatePicker} name='dateOfIssue' />
                                            {errorMessage('dateOfIssue')}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Ethnicity</label>
                                    <Field
                                        name={"ethnicityIds"}
                                        component={Select}
                                        options={ETHNICITIES}
                                        placeholder='Choose Ehincity'
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>Primary Language</label>
                                    <Field
                                        name="primaryLanguage"
                                        component={Select}
                                        options={PRIMARY_LANGUAGES}
                                        placeholder='Choose Primary Language'
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                    {errorMessage('primaryLanguage')}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Primary Language used by Parent(s)</label>
                                    <Field
                                        name="parentsPrimaryLanguage"
                                        component={Select}
                                        options={PRIMARY_LANGUAGES}
                                        placeholder='Choose Primary Language used by Parent(s)'
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Understands English? (Speak, Read, Write)</label>
                                    <Field
                                        component={RadioGroup}
                                        options={UNDERSTAND_ENGLISH}
                                        name='understandsEnglish'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Marital Status</label>
                                    <Field
                                        component={RadioGroup}
                                        options={MARTIAL_STATUS_TYPES}
                                        name='maritalStatus'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='3'>
                                <FormGroup>
                                    <label>If married, U.S. Military Spouse?</label>
                                    <Field
                                        component={RadioGroup}
                                        options={BOOLEAN_TYPES}
                                        name='isMilitarySpose'
                                    />
                                    {errorMessage('isMilitarySpose')}
                                </FormGroup>
                            </Col>
                            <Col md='3'>
                                <FormGroup>
                                    <label>I am a dependent of a Veteran</label>
                                    <Field
                                        component={RadioGroup}
                                        options={BOOLEAN_TYPES}
                                        name='isDependentVeteran'
                                    />
                                    {errorMessage('isDependentVeteran')}
                                </FormGroup>
                            </Col>
                            {
                                ((values.isDependentVeteran === "true" || values.isDependentVeteran === true)
                                    || (values.isMilitarySpose === "true" || values.isMilitarySpose === true)) &&
                                <>
                                    <Col md="3">
                                        <FormGroup className="text-center">
                                            <label>Upload your USID (Ununiformed Service ID Card) or equivalent military issued ID.</label>
                                            <ImageUpload
                                                accept='application/pdf'
                                                defaultValue={values.militaryDependantVerification}
                                                onChange={({ fileUrl }) =>
                                                    setFieldValue("militaryDependantVerification", fileUrl)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='3'>
                                        <FormGroup>
                                            <label>Enter Date of Issue or Expiration (Whichever is listed on document)</label>
                                            <Field component={DatePicker} name='sposeDateOfIssue' />
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                        <div className='full-divider' />
                        <Row>
                            <Col md='12'>
                                <CardTitle tag='h4' className='title'>
                                    Family
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-between">
                            <Col md='5'>
                                <CustomInput
                                    label='Total Family / Household Income'
                                    value="households"
                                    type="number"
                                    min="1"
                                    hasError={labelClasses("households")}
                                />
                            </Col>
                            <Col md='5'>
                                <label>Total Family / Household Income</label>
                                <div className="currencyinput">
                                    <span>$</span>
                                    <Field
                                        component={Input}
                                        name='householdAnnualIncome'
                                        type="number"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Income Verification</label>
                                    <Field
                                        name="annualIncomeType"
                                        component={Select}
                                        options={INCOME_VERIFICATION_TYPES.map((val, i) => ({ label: val, value: i + 1 }))}
                                        placeholder='Choose Income Verification'
                                        value={values.annualIncomeType}
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <FormGroup className="text-center">
                                    <label>Upload Document</label>
                                    <ImageUpload
                                        defaultValue={values.annualIncomeDocumentData}
                                        accept='application/pdf'
                                        onChange={({ fileUrl }) =>
                                            setFieldValue("annualIncomeDocumentData", fileUrl)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="8">
                                <FormGroup>
                                    <label>Date on Document (Must be within last 90 days from time of upload)</label>
                                    <Field
                                        name="documentDate"
                                        component={DatePicker}
                                        isValidDate={(currentDate) => currentDate.isBefore(moment(new Date().getTime())) && currentDate.isAfter(moment().subtract(90, 'days'))}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.annualIncomeType?.value === 8 &&
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>Description</label>
                                        <Field
                                            name="annualIncomeType"
                                            component={Input}
                                            placeholder='Please explain'
                                            value={explainedAnnual}
                                            onChange={(e) => setExplainedAnnual(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Additional Income Verification</label>
                                    <Field
                                        name="additionalIncome"
                                        component={Select}
                                        options={INCOME_VERIFICATION_TYPES.map((val, i) => ({ label: val, value: i + 1 }))}
                                        placeholder='Choose Additional Income Verification'
                                        value={values.additionalIncome}
                                        getOptionValue={(option) => option.value}
                                        getOptionLabel={(option) => option.label}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <FormGroup className="text-center">
                                    <label>Upload Document</label>
                                    <ImageUpload
                                        defaultValue={values.additionalIncomeDocumentData}
                                        accept='application/pdf'
                                        onChange={({ fileUrl }) =>
                                            setFieldValue("additionalIncomeDocumentData", fileUrl)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="8">
                                <label>Date on Document (Must be within last 90 days from time of upload)</label>
                                <Field
                                    name="additionalIncomeDocumentDate"
                                    component={DatePicker}
                                    isValidDate={(currentDate) => currentDate.isBefore(moment(new Date().getTime())) && currentDate.isAfter(moment().subtract(90, 'days'))}
                                />
                            </Col>
                        </Row>
                        {
                            values.additionalIncome?.value === 8 &&
                            <Row>
                                <Col>
                                    <label>Description</label>
                                    <Field
                                        name="additionalIncome"
                                        component={Input}
                                        placeholder='Please explain'
                                        value={explainedAnnual}
                                        onChange={(e) => setExplainedAnnual(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        }
                        <FieldArray name='guardians'>
                            {() =>
                                values.guardians.map((guardian, i) => {
                                    return (
                                        <div key={i}>
                                            <Row>
                                                <Col md="12">
                                                    <CardTitle tag="h5">Family Member {i + 1}</CardTitle>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <CustomInput
                                                        label='First Name'
                                                        value={`guardians.${i}.firstName`}
                                                        hasError={labelClasses(`guardians.${i}.firstName`)}
                                                    />
                                                </Col>
                                                <Col md='6'>
                                                    <CustomInput
                                                        label='Last Name'
                                                        value={`guardians.${i}.lastName`}
                                                        hasError={labelClasses(`guardians.${i}.lastName`)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <CustomInput
                                                        label='Occupation'
                                                        value={`guardians.${i}.occupation`}
                                                        hasError={labelClasses(`guardians.${i}.occupation`)}
                                                    />
                                                </Col>
                                                <Col md='6'>
                                                    <FormGroup>
                                                        <label>Education Level</label>
                                                        <Field
                                                            name={`guardians.${i}.educationLevel`}
                                                            component={Select}
                                                            options={GRADE_TYPES}
                                                        />
                                                        {errorMessage(`guardians.${i}.educationLevel`)}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <FormGroup>
                                                        <label>Is Guardian an Emergency Contact?</label>
                                                        <Field
                                                            component={RadioGroup}
                                                            options={[
                                                                { label: 'Yes', value: true },
                                                                { label: 'No', value: false },
                                                            ]}
                                                            name={`guardians.${i}.isEmergencyContact`}
                                                        />
                                                        {errorMessage(`guardians.${i}.isEmergencyContact`)}
                                                    </FormGroup>
                                                </Col>
                                                <Col md='6'>
                                                    <FormGroup>
                                                        <label>Relationship</label>
                                                        <Field
                                                            name={`guardians.${i}.relationship`}
                                                            component={Select}
                                                            options={relationship}
                                                            placeholder='Search...'
                                                            getOptionValue={(option) => option.id}
                                                            getOptionLabel={(option) => option.displayName}
                                                        />
                                                        {errorMessage(`guardians.${i}.relationship`)}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })
                            }
                        </FieldArray>
                        <Button
                            className='btn-fill float-right'
                            color='primary'
                            type='submit'
                        >
                            Save {isLoading && <Spinner size='sm' className='ml-2' />}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default withRouter(BackgroundInfo);
