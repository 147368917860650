import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col
} from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Api from 'Helpers/Api';
import ParticipantList from './participantList';
import Invite from './invite';
import { ACCEPTDENYACTION } from '../../../Helpers/constants'

const ParticipantTabForProject = (props) => {
    const { id } = props.match.params;
    const [projDetails, setProjDetails] = useState();
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const { userId } = props;

    const getProjectDetails = async () => {
        try {
            const result = await Api.getProjectDetails(window.atob(id));
            setProjDetails(result);
        } catch (error) {
            console.error('getProjectDetails -> error', error);
        }
    };

    const getProjectParticipants = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await Api.getOrganizationProjectParticipants(window.atob(id), projDetails.primaryOrganizationGuid);
                let projParticipants = [];
                projParticipants = result.map(participant => ({
                    participantId: participant.participantId,
                    projectOrganizationId: participant.projectOrganizationId,
                    acceptedBy: participant.acceptedBy,
                    invitedBy: participant.invitedBy,
                    declinedBy: participant.declinedBy,
                    status: participant.status,
                    name: participant.users?.fullName ?? "",
                    city: participant.users?.city ?? "",
                    state: participant.users?.state ?? "",
                }));
                setParticipants(projParticipants);
                resolve();
            } catch (error) {
                console.error('getProjectParticipants -> error', error);
                reject();
            }
        })
    };

    const handleApproveDenyInvite = (participantId, projectOrganizationId, status) => {
        let participant = participants.find((item) => item.participantId === participantId);
        const acceptOrDenyText = status === ACCEPTDENYACTION.ACCEPT ? "accept" : "deny";
        let message = `You want to ${acceptOrDenyText} the invite`;
        if (participant.name) {
            message = message + ` from ${participant.name}?`;
        }
        else {
            message = message + "?";
        }
        let confirmButtonColor = '#ec250d';
        let icon = "warning";
        if (status === ACCEPTDENYACTION.ACCEPT) {
            confirmButtonColor = '#009100';
            icon = "success";
        }
        Swal.fire({
            title: 'Are you sure?',
            text: message,
            icon: icon,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Yes, ${acceptOrDenyText} invite!`,
            denyButtonText: `Cancel`,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: '#1d8cf8',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                approveOrDenyInvite(participantId, projectOrganizationId, status);
            }
        });
    };

    const approveOrDenyInvite = async (participantId, projectOrganizationId, status) => {
        try {
            const reqParam = {
                participantId: participantId,
                projectOrganizationId: projectOrganizationId,
                userId: userId,
                status: status
            };
            const response = await Api.approveDenyInvitation(reqParam);
            if (response.statusCode !== 200) {
                return Swal.fire({
                    icon: 'error',
                    title: response.message,
                });
            }
            let data = [...participants];
            let participant = participants.find((item) => item.participantId === participantId);
            if (participant && status === ACCEPTDENYACTION.ACCEPT) {
                participant.status = "accepted";
            }
            else {
                data.find((o, i) => {
                    if (o.participantId === participantId) {
                        data.splice(i, 1);
                        return true;
                    }
                    return false;
                });
            }

            setParticipants(data);
        } catch (error) {
            console.error('approveOrDenyInvite -> error', error);
        }
    }

    const handleDeleteParticipant = (participantId, projectOrganizationId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The participant will be removed from the project.",
            icon: "warning",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Yes, delete participant!`,
            denyButtonText: `Cancel`,
            confirmButtonColor: '#ec250d',
            cancelButtonColor: '#1d8cf8',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                deleteProjectParticipant(participantId, projectOrganizationId);
            }
        });
    };

    const deleteProjectParticipant = async (participantId, projectOrganizationId) => {
        try {
            const reqParam = {
                participantId: participantId,
                projectOrganizationId: projectOrganizationId
            };
            const response = await Api.deleteProjectParticipant(reqParam);
            if (response.statusCode !== 200) {
                return Swal.fire({
                    icon: 'error',
                    title: response.message,
                });
            }
            let data = [...participants];
            data.find((o, i) => {
                if (o.participantId === participantId) {
                    data.splice(i, 1);
                    return true;
                }
                return false;
            });
            setParticipants(data);
        } catch (error) {
            console.error('deleteProjectParticipant -> error', error);
        }
    }

    const refreshParticipantList = () => {
        setLoading(true);
        getProjectParticipants()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }

    useEffect(() => {
        getProjectDetails();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (projDetails) {
            getProjectParticipants()
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [projDetails]);

    return (
        <div className='content'>
            <Invite
                projDetails={projDetails}
                refreshParticipantList={refreshParticipantList}
            />
            <Row>
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag='h4' className='float-left'>
                                Manage Participants for {projDetails && projDetails.name}
                            </CardTitle>

                            {/* <Link to={`/project`}>
                                <Button className='btn-link float-right mr-3' color='info'>
                                    <i className='tim-icons icon-minimal-left' /> Go back
                                </Button>
                            </Link> */}
                        </CardHeader>
                        <CardBody>
                            <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                                <Tab label="Accepted">
                                    <ParticipantList
                                        participants={participants.filter(x => x.status.toLowerCase() === "accepted")}
                                        isAccepted={true}
                                        loading={loading}
                                        handleDeleteParticipant={handleDeleteParticipant}
                                    />
                                </Tab>
                                <Tab label="Waiting">
                                    <ParticipantList
                                        participants={participants.filter(x => x.status.toLowerCase() === "applied" || x.status.toLowerCase() === "invited")}
                                        isAccepted={false}
                                        loading={loading}
                                        handleApproveDenyInvite={handleApproveDenyInvite}
                                        handleDeleteClick={deleteProjectParticipant}
                                    />
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapReduxStateToProps = (state) => ({
    userId: state.auth.userId
});

export default connect(mapReduxStateToProps, null)(withRouter(ParticipantTabForProject));