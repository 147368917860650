import { AUTHORIZED_PLACE } from "Helpers/constants";
import { GENERIC_REQUIRED } from "Helpers/Validation/messages";
import * as yup from "yup";
import { ETHNICITIES, INCOME_VERIFICATION_TYPES, PRIMARY_LANGUAGES } from "./constants";
import { GRADE_TYPES } from "Helpers/constants";

const findItem = (value, allValues) => {
  return value ? allValues.find(val => val.value === value) : (typeof allValues[0].value === "number" ? 0 : false);
}

const defaultGuardian = {
  firstName: "",
  lastName: "",
  occupation: "",
  educationLevel: "",
  isEmergencyContact: false,
  relationship: 0,
};

const initialValues = (details, relationship) => ({
  genderAtBirth: details.genderAtBirth || 0,
  genderIdentified: details.genderIdentified || 0,
  citizenship: findItem(details.citizenship, AUTHORIZED_PLACE),
  sexualOrientation: details.sexualOrientation || 0,
  uscis: details.uscis || "",
  dateOfIssue: details.dateOfIssue ? new Date(details.dateOfIssue) : "",
  usWorkPermitVerification: details.usWorkPermitVerification || "",
  ethnicityIds: details.ethnicityIds?.length ?
    details.ethnicityIds.map(val => ETHNICITIES.find((({ value }) => value === val))) :
    [],
  primaryLanguage: findItem(details.primaryLanguage, PRIMARY_LANGUAGES),
  parentsPrimaryLanguage: findItem(details.parentsPrimaryLanguage, PRIMARY_LANGUAGES),
  understandsEnglish: details.understandsEnglish || 0,
  maritalStatus: details.maritalStatus || 0,
  isMilitarySpose: !!details.isMilitarySpose,
  isDependentVeteran: !!details.isDependentVeteran,
  militaryDependantVerification: details.militaryDependantVerification || "",
  sposeDateOfIssue: details.sposeDateOfIssue ? new Date(details.sposeDateOfIssue) : "",
  households: details.households || 1,
  householdAnnualIncome: details.householdAnnualIncome || 0,
  annualIncomeDocumentData: details.incomeVerification || "",
  documentDate: details.documentDate ? new Date(details.documentDate) : "",
  annualIncomeType: details.annualIncomeType ? { label: INCOME_VERIFICATION_TYPES.find((val, i) => i + 1 === details.annualIncomeType), value: details.annualIncomeType } : "",
  additionalIncome: details.additionalIncome ? { label: INCOME_VERIFICATION_TYPES.find((val, i) => i + 1 === details.additionalIncome), value: details.additionalIncome } : "",
  additionalIncomeDocumentDate: details.additionalIncomeDocumentDate ? new Date(details.additionalIncomeDocumentDate) : "",
  additionalIncomeDocumentData: details.additionalIncomeVerification || "",
  ssn: details.ssn || "",
  guardians:
    details.guardians && details.guardians.length
      ? details.guardians.slice(-2).map((data) => {
        if (typeof data.relationship === "number") {
          data.relationship = relationship.find(
            (option) => option.id === data.relationship
          );
          data.educationLevel = GRADE_TYPES.find(type => type.value === parseInt(data.educationLevel))
        }

        return { ...data };
      })
      : [defaultGuardian, defaultGuardian],
});

const validationSchema = yup.object().shape({
  visaRegistrationNumber: yup.string(),
  primaryLanguage: yup
    .string()
    .min(2, "Primary Language is Required")
    .required(GENERIC_REQUIRED),
  ssn: yup
    .string()
    .max(20, "Social Security can not be more than 20 characters."),
  households: yup.number().min(1, "Social Security can not be less than 1"),
  uscis: yup
    .string()
    .max(9, "USCIS can not be more than 9 characters.")
    .min(7, "USCIS can not be less than 7 characters."),
  guardians: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .max(80, "First name can not be more than 80 characters."),
      lastName: yup
        .string()
        .max(80, "Last name can not be more than 80 characters."),
      occupation: yup
        .string()
        .max(100, "Occupation can not be more than 100 characters."),
      educationLevel: yup
        .string()
        .max(50, "Education Level can not be more than 50 characters."),
    })
  ),
});

export { initialValues, validationSchema };
