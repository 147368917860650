const visionOptions = [
  {value:1, label:"Known eye condition/defect in vision"},
  {value:2, label:"Wears glasses"},
  {value:3, label:"Wears contact lenses"},
  {value:4, label:"Glasses must be worn at all times"},
]

const hearingOptions = [
  {value: 1, label: 'Known hearing condition / defect in hearing' },
  {value: 2, label: 'Wears hearing aid' },
  {value: 3, label: 'Preferential Seating' },
]

const medicalConditionsOptions = [
  {value: 1, label: 'Asthma' },
  {value: 2, label: 'Peanut allergy' },
  {value: 3, label: 'Bee sting allergy' },
  {value: 4, label: 'Epilepsy' },
  {value: 5, label: 'Diabetes' },
  {value: 6, label: 'Heart Condition' },
  {value: 7, label: 'Seizurers' },
  {value: 8, label: 'Other' },
]

export {
  visionOptions,
  hearingOptions,
  medicalConditionsOptions
}
