import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Api from 'Helpers/Api';
import { connect } from 'react-redux';
import moment from 'moment';
import { USER_ROLES } from 'Helpers/constants';
import { hasRoles } from 'Helpers/utils';
import { searchParticipants } from 'Redux/Actions/search.action';
import { encodeId } from 'Helpers/utils';
let tableColumns = [];

const ParticipantListByOrganization = (props) => {
  const { id } = props.match.params;
  const [participants, setParticipants] = useState({
    records: [],
    totalRecords: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [orgDetails, setOrgDetails] = useState({});
  const { user } = props;

  const getOrganizationParticipants = async (pageNumber) => {
    try {
      const organizationId = window.atob(id);
      const searchName = document.getElementById('search-name').value;

      const result = await Api.getOrganizationParticipants(organizationId, pageNumber, 10, searchName);
      setParticipants(result);
    } catch (error) { }
  };

  const getOrganizationDetails = async () => {
    try {
      const result = await Api.getOrganizationById(window.atob(id));
      setOrgDetails(result);
    } catch (error) {
      console.error('getOrganizationDetails -> error', error);
    }
  };

  const getInitValues = async () => {
    if (
      hasRoles(user, [
        USER_ROLES.ADMIN,
        USER_ROLES.MANAGER,
        USER_ROLES.DIRECTOR,
      ])
    ) {
      tableColumns.push({
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        filterable: false,
      });
    }

    await Promise.all([
      getOrganizationParticipants(),
      getOrganizationDetails(),
    ]);
  };
  
  useEffect(() => {
    getInitValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = (participantId) => {
    window.open(`/p/${participantId}`, "_blank");
  };

  const handleOrgParticipantDeleteClick = (participantId) => {
    let participant = participants.records.find((item) => item.participantId === participantId);
    let organizationUserId = orgDetails.organizationUsers[0].id;
    Swal.fire({
      title: 'Are you sure?',
      text: `Once deleted, you will not be able to recover ${participant.name}`,
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Yes, delete it!`,
      denyButtonText: `Cancel`,
      confirmButtonColor: '#ec250d',
      cancelButtonColor: '#1d8cf8',
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        removeOrganizationParticipant(organizationUserId, participantId)
      }
    });
  };

  const removeOrganizationParticipant = async (organizationUserId, participantId) => {
    try {
      const response = await Api.removeParticipant(organizationUserId, participantId);
      if (response.statusCode !== 200) {
        return Swal.fire({
          icon: 'error',
          title: response.message,
        });
      }
      let data = [...participants];
      data.find((o, i) => {
        if (o.id === participantId) {
          data.splice(i, 1);
          return true;
        }
        return false;
      });
      setParticipants(data);
    } catch (error) {
      console.error('deleteParticipant -> error', error);
    }
  }

  const handleViewNotes = (id) => {
    props.history.push('/participant/' + encodeId(id) + '/notes');
  };

  const goToPreviousPage = () => {
    getOrganizationParticipants(participants.pageNumber - 1);
  }

  const goToNextPage = () => {
    getOrganizationParticipants(participants.pageNumber + 1);
  }
  
  return (
    <div className='content'>
      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag='h4' className='float-left'>
                Manage Participants for {orgDetails.name || ''}
              </CardTitle>
              <Link
                to={`/organization/${window.btoa(
                  orgDetails.id
                )}/participants-search`}
              >
                <Button color='info' className='btn-round float-right mr-3'>
                  New Participant
                  <i className='tim-icons icon-minimal-right ml-1' />
                </Button>
              </Link>
              <Link to={`/organization`}>
                <Button className='btn-link float-right mr-3' color='info'>
                  <i className='tim-icons icon-minimal-left' /> Go back
                </Button>
              </Link>
            </CardHeader>
            <CardBody>
              <div className="row mt-3 mb-4">
                <div className="col-lg-6"></div>
                <div className="col-lg-4">
                  <div className="has-label form-group m-0">
                    <input id="search-name" name="name" maxLength="160" type="text" className="form-control" placeholder="Search by Name or Email" />
                  </div>
                </div>
                <div className="col-lg-2 text-right">
                  <button className="btn-round mt-0 mr-3 btn btn-info" onClick={() => getOrganizationParticipants(1)}>
                    Search
                  </button>
                </div>
              </div>
              <div className="ReactTable -striped -highlight">
                <table className="table">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>CITY</th>
                      <th>STATE</th>
                      <th>DATE OF BIRTH</th>
                      <th>ASSIGNED TO</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants.records.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.participantName}</td>
                          <td>{item.city}</td>
                          <td>{item.state}</td>
                          <td>{moment(item.dateOfBirth).format('MMMM DD, YYYY')}</td>
                          <td>{item.assignedTo}</td>
                          <td>
                            {item.canModify &&
                              <div className="actions-right">
                                <button
                                  type="button"
                                  name="Note icon"
                                  className="btn-icon btn-link like btn btn-primary btn-sm"
                                  onClick={() => handleViewNotes(item.participantId)}
                                >
                                  <i className="tim-icons icon-notes"></i>
                                </button>
                                <button
                                  type="button"
                                  name="Edit icon"
                                  className="btn-icon btn-link like btn btn-warning btn-sm"
                                  onClick={() => handleEditClick(item.participantId)}
                                >
                                  <i className="tim-icons icon-pencil"></i>
                                </button>
                                <button
                                  type="button"
                                  name="Remove icon"
                                  className="btn-icon btn-link like btn btn-danger btn-sm"
                                  onClick={() => handleOrgParticipantDeleteClick(item.participantId)}
                                >
                                  <i className="tim-icons icon-trash-simple"></i>
                                </button>
                              </div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination-bottom">
                  <div className="-pagination">
                    <div className="-previous">
                      <button
                        type="button"
                        className="-btn"
                        onClick={() => goToPreviousPage()}
                        disabled={!participants.hasPreviousPage}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="-center">
                      <span className="-pageInfo">
                        Page
                        <div className="-pageJump">
                          <input aria-label="jump to page" type="number" value={participants.pageNumber} />
                        </div>
                        &nbsp; of &nbsp;
                        <span className="-totalPages">{participants.totalPages}</span>
                      </span>
                      <span className="select-wrap -pageSizeOptions">
                        <select aria-label="rows per page">
                          <option value="10">10 rows</option>
                        </select>
                      </span>
                    </div>
                    <div className="-next">
                      <button
                        type="button"
                        className="-btn"
                        onClick={() => goToNextPage()}
                        disabled={!participants.hasNextPage}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
/*
<Table
  filterable
  columns={tableColumns}
  dataTable={participants}
  loading={loading}
  actionsVisibility={{
    isViewHidden: true,
    isEditHidden: !canModify,
    isDeleteHidden: canModify,
    isDeleteOrgParticipantHidden: !canModify,
    isNotesViewHidden: !canModify
  }}
  handleEditClick={handleEditClick}
  handleOrgParticipantDeleteClick={handleOrgParticipantDeleteClick}
  handleViewNotes={handleViewNotes}
  shouldReturnProps={true}
/>
*/

const mapReduxStateToProps = (state) => ({
  user: state.auth.user,
  userId: state.auth.userId,
  participants: state.search.participants,
});

export default connect(mapReduxStateToProps, { searchParticipants })(
  withRouter(ParticipantListByOrganization)
);
