const EMAIL_REQUIRED = "Email is required";
const EMAIL_INVALID = "Invalid email";

const STRONG_PASSWORD =
    "Must contain at least 1 lowercase, uppercase, numeric character and special charater (e.i. !@#$%&) as well as 8 charaters long";
const PASSWORD_REQUIRED = "Password is required";
const PASSWORD_NOMATCH = "Passwords do not match";
const PASSWORD_MINLENGTH = "Password minimum length is 8 characters";
const PASSWORD_MAXLENGTH = "Password maximum length is 32 characters";
const PASSWORD_CONFIRM = "Password must be confirmed";

const FIRSTNAME_REQUIRED = "First name is required";
const FIRSTNAME_MINLENGTH = "First name needs to be at least 2 characters";
const FIRSTNAME_MAXLENGTH = "First name can not be more than 80 characters";

const MIDDLEINITIAL_MAXLENGTH = "Middle initial can only be 1 character";

const LASTNAME_REQUIRED = "Last name is required";
const LASTNAME_MINLENGTH = "Last name needs to be at least 3 characters";
const LASTNAME_MAXLENGTH = "Last name can not be more than 80 characters";

const NUMERIC_INVALID = "The value you provide is invalid";
const USERNAME_REQUIRED = "User name is required";
const USERNAME_MINLENGTH = "User name needs to be at least 2 characters";
const USERNAME_MAXLENGTH = "User name can not be more than 80 characters";

const BIRTHDAY_REQUIRED = "Birthday is required";
const BIRTHDAY_AGE_RESTRICTION =
    "You must be at least 13 years and older to register with Eleveight";

const ORG_NAME_REQUIRED = "Display name is required.";
const ADDRESS_REQUIRED = "Address is required.";
const CITY_REQUIRED = "City is required.";
const CONTACT_NUMBER_REQUIRED = "Contact number is required.";
const COUNTRY_REQUIRED = "Country is required.";
const STATE_REQUIRED = "State is required.";
const STREET_REQUIRED = "Street is required.";
const POSTALCODE_REQUIRED = "Postal code is required.";
const CONTACT_NAME_REQUIRED = "Contact name is required.";
const CONTACT_EMAIL_REQUIRED = "Contact email is required.";
const WEBSITE_URL_REQUIRED = "Website URL is required.";
const GENERIC_REQUIRED = "Required.";
const SSN_REQUIRED = 'Social Security Number is required';
const BIO_REQUIRED = "Bio information required.";

// Organization
const ORGANIZATION_NAME_MAXLENGTH =
    "Organization name can not be more than 80 characters.";

const GENERIC_MAXLENGTH = "Can not be more than 80 characters.";
const ZIPCODE_MAXLENGTH = "Can not be more than 6 characters.";
const EMAIL_MAXLENGTH = "Can not be more than 100 characters.";
const TEXTAREA_MAXLENGTH = "Can not be more than 5000 characters.";
const NAME_MAXLENGTH = "Name can not be more than 80 characters.";

export {
    EMAIL_INVALID,
    EMAIL_REQUIRED,
    PASSWORD_REQUIRED,
    PASSWORD_MINLENGTH,
    PASSWORD_MAXLENGTH,
    PASSWORD_NOMATCH,
    PASSWORD_CONFIRM,
    STRONG_PASSWORD,
    FIRSTNAME_REQUIRED,
    FIRSTNAME_MINLENGTH,
    FIRSTNAME_MAXLENGTH,
    MIDDLEINITIAL_MAXLENGTH,
    LASTNAME_REQUIRED,
    LASTNAME_MINLENGTH,
    LASTNAME_MAXLENGTH,
    NUMERIC_INVALID,
    USERNAME_REQUIRED,
    USERNAME_MINLENGTH,
    USERNAME_MAXLENGTH,
    BIRTHDAY_REQUIRED,
    BIRTHDAY_AGE_RESTRICTION,
    ORG_NAME_REQUIRED,
    ADDRESS_REQUIRED,
    CITY_REQUIRED,
    GENERIC_REQUIRED,
    CONTACT_NUMBER_REQUIRED,
    COUNTRY_REQUIRED,
    STATE_REQUIRED,
    STREET_REQUIRED,
    POSTALCODE_REQUIRED,
    CONTACT_NAME_REQUIRED,
    CONTACT_EMAIL_REQUIRED,
    WEBSITE_URL_REQUIRED,
    ORGANIZATION_NAME_MAXLENGTH,
    GENERIC_MAXLENGTH,
    ZIPCODE_MAXLENGTH,
    EMAIL_MAXLENGTH,
    NAME_MAXLENGTH,
    TEXTAREA_MAXLENGTH,
    SSN_REQUIRED,
    BIO_REQUIRED,
};
