import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Api from 'Helpers/Api';
import Table from '../../../Common/Table';
import { USER_ROLES } from 'Helpers/constants';
import { hasRoles } from 'Helpers/utils';
import moment from 'moment';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row
} from "reactstrap";

const Projects = (props) => {

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionsVisibility, setActionsVisibility] = useState({});
    const { user } = props;
    const formatDate = (format = 'MMMM DD, YYYY', value) => moment(value).format(format);

    const getProjects = async () => {
        setLoading(true);
        try {
            const result = await Api.getByParticipantId();

            let projctParticipants = result
                .map((projectPart) => {
                    return {
                        ...projectPart,
                        acceptedDate: formatDate(
                            'MMMM DD, YYYY',
                            projectPart.acceptedDate
                        ),
                    };
                });

            setProjects(projctParticipants);
        } catch (error) {
            console.error('getProjects -> error', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setActionsVisibility({ 
            isEditHidden: hasRoles(user, [USER_ROLES.ADMIN]),
            isDetailsHidden: hasRoles(user, [USER_ROLES.ADMIN]),
            isDeleteHidden: !hasRoles(user, [USER_ROLES.ADMIN]), 
        });

        getProjects();
    }, []);


    return (
        <Row>
            {loading ? (
                <Card>
                    <CardHeader>
                        <div
                            style={{
                                width: '100%',
                                height: '500px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            Loading...
                        </div>
                    </CardHeader>
                </Card>
            ) : (
                <Card className="card-tasks">
                    <CardHeader>
                        <CardTitle tag="h5">Project Participants</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className="table-full-width table-responsive">

                            <Table
                                columns={[

                                    {
                                        Header: 'Name',
                                        accessor: 'name',
                                        filterable: false,
                                    },
                                    {
                                        Header: 'Organization Name',
                                        accessor: 'organizationName',
                                        filterable: false,
                                    },
                                    {
                                        Header: 'Accepted Date',
                                        accessor: 'acceptedDate',
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                filterable
                                dataTable={projects}
                                loading={loading}
                                actionsVisibility={actionsVisibility}
                                showPaginationBottom={false}
                            />
                        </div>
                    </CardBody>
                </Card>
            )}
        </Row>

    );
}

const mapReduxStateToProps = (state) => ({
    user: state.auth.user,
    userId: state.auth.userId,
});

export default connect(mapReduxStateToProps)(withRouter(Projects));