import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Api from 'Helpers/Api';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col, Table
} from "reactstrap";

// core components
import LandingHeaderNavbar from "Components/Common/LandingPageNavbar";
import Footer from "Components/Common/LandingPageFooter";
import { PROJECTTYPE } from "Helpers/constants";
import landing_video from "Assets/video/landing-video.mp4"
import "./landing.scss";

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    console.log("props", props.userId)
    this.state = {
      projects: [],
      expandedRows : [],
      preLaoder:false

    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
    this.getProjects();
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
  }

  async getProjects() {
    try {
      this.setState({preLaoder:true},async ()=>{
       await Api.getPublicProjects().then((res)=>{
         res.map((project) => {
        //Adding project type
        project.projectType = PROJECTTYPE.filter(
          (p) => p.value === project.projectType
        )[0]?.label;

        return project;
      });
      this.setState({
        projects:res
      })
         this.setState({
        preLaoder:false
      })
      });
      })
   

     
    } catch (error) {
      console.error('getPublicProjects -> error', error);
    }
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded ?
      currentExpandedRows.filter(id => id !== rowId) :
      currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItem(project) {
    const clickCallback = () => this.handleRowClick(project.id);
    const itemRows = [
    <tr onClick={clickCallback} key={"row-dalign-items: center;ata-" + project.id}>
        
                                  <td>
                                    {project.name}
                                  </td>
                                  <td>
                                    {project.organizationName}
                                  </td>
                                  <td>
                                  {project.location}
                                  </td>
                                  <td>
                                    {moment(project?.createdDate).format("do MMMM yyyy")}
                                  </td>
                                  <td>
                                    <Button
                                      color="success"
                                      onClick={()=>this.apply()}
                                      size="sm"
                                    >
                                      Apply
                                    </Button>
                                  </td>
                                </tr>
      ];
      
      if(this.state.expandedRows.includes(project.id)) {
          itemRows.push(
              <tr key={"row-expanded-" + project.id} >
                  <td colSpan="5">
                    
                  <Card className="card-blog card-plain blog-horizontal">
                          <CardBody>
                            <CardTitle tag="h3">
                              <a
                                href={this.props.userId === "" ?'/register':'/project' }
                              >
                                {project.name}
                              </a>

                            </CardTitle>
                            <Row>
                              <Col lg='3'>
                                <h5 className='text-muted'>Start Date</h5>
                                <p className='mb-4'>
                                  {project.startDate && moment(project.startDate).format('do MMMM yyyy')}
                                </p>
                              </Col>
                              <Col lg='3'>
                                <h5 className='text-muted'>End Date</h5>
                                <p className='mb-4'>
                                  {project.endDate && moment(project.endDate).format('do MMMM yyyy')}
                                </p>
                              </Col>
                              <Col lg='3'>
                                <h5 className='text-muted'>Project Type</h5>
                                <p className='mb-4'>{project.projectType || "No Type"}</p>
                              </Col>
                              <Col lg='3'>
                                <h5 className='text-muted'>Primary Organization</h5>
                                <p className='mb-4'>{project.organizationName}</p>
                              </Col>
                              <Col md={12}>
                                <h5 className='text-muted'>Project Description</h5>
                                <p className='mb-4'>{project.description}
                                <br/>
                                 <br/>
                                <a
                                  href={this.props.userId === "" ?'/register':'/project' }
                                >
                                 Apply
                                </a>
                               
                                </p>
                              </Col>
                            </Row>
                          </CardBody>
                      
                    </Card>
                    </td>
              </tr>
          );
      }
      
      return itemRows;    
  }

  apply() {
    if (this.props.userId === "") {
      this.props.history.push("/register");
    }else{
      this.props.history.push("/project");
    }
  }

  render() {
    let allItemRows = [];

    this.state.projects.forEach(item => {
      const perItemRows = this.renderItem(item);
      allItemRows = allItemRows.concat(perItemRows);
    });

    return (
      <>
        <LandingHeaderNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header" id="home">
            <img
              alt="..."
              className="path"
              src={require("Assets/img/blob.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("Assets/img/path2.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("Assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes wave"
              src={require("Assets/img/waves.png")}
            />
            <img
              alt="..."
              className="shapes squares"
              src={require("Assets/img/patrat.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("Assets/img/cercuri.png")}
            />
            <Container>
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">
                    Level up your Case Management
                  </h1>
                  <h3 className="text-white">
                    A network for schools, nonprofits (NGOs), government agencies, philanthropists and the people they serve.
                  </h3>
                  <div className="btn-wrapper mb-3">
                    <a
                        className="font-weight-bold text-white mt-5"
                        href="/register"
                        // onClick={e => e.preventDefault()}
                      >
                        Create an account{" "}
                        <i className="tim-icons icon-minimal-right text-white" />
                      </a>
                  </div>
                  <div className="btn-wrapper">
                    <div className="button-container">
                      <Button
                        className="btn-icon btn-simple btn-round mr-1"
                        color="neutral"
                        href="https://www.instagram.com/eleveight.us"
                        // onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-instagram" />
                      </Button>
                      <Button
                        className="btn-icon btn-simple btn-round mr-1"
                        color="neutral"
                        href="https://www.facebook.com/eleveight.us"
                        // onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button
                        className="btn-icon btn-simple btn-round"
                        color="neutral"
                        href="https://www.youtube.com/channel/UCtAkgEFWnmfSpIAOPZDuM6w"
                        // onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-youtube" />
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg="4" md="5"className="center-img">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("Assets/img/eleveight_jobs.png")}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <section className="section section-lg">
            <section className="section">
              <img
                alt="..."
                className="path"
                src={require("Assets/img/path4.png")}
              />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col className="mt-lg-5" lg="5">
                    <video controls autoPlay muted className="w-100">
                      <source src={landing_video} type="video/mp4" />
                    </video>
                  </Col>
                  <Col lg="6">
                    <div className="pl-lg-5">
                      <h1>
                        Built by experienced social service providers, Eleveight allows you to track your clients as they are referred throughout your network while keeping data confidential.
                      </h1>
                      <p>
                       Does your organization, school, business or municipality want to see how Eleveight can help you administer your projects, grants and everyone involved? Contact us to request a demo. 
                      </p>
                      <br />
                      <br />
                      <a
                        className="font-weight-bold text-white mt-5"
                        href="mailto:support@eleveight.co"
                        // onClick={e => e.preventDefault()}
                      >
                        Contact us{" "}
                        <i className="tim-icons icon-minimal-right text-white" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
          <section className="section section-lg landingTable" id="projects">
            <div className="tables-1">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <h2 className="title mb-4">Find opportunities</h2>
                    <div className="section-space" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-auto" md="12">
                    <div>
                      <Card className="card-plain">
                        <CardBody>
                          <Table className="table-pricing" responsive>
                            <thead className="text-primary">
                              <tr>
                                <th>
                                  <h6>Project</h6>
                                </th>
                                <th>
                                  <h6>Organization</h6>
                                </th>
                                <th>
                                  <h6>Location</h6>
                                </th>
                                <th>
                                  <h6>Created Date</h6>
                                </th>
                                <th>
                                  <h6>Action</h6>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allItemRows.length > 0 ? allItemRows :
                                (<tr>
                                  <td colSpan="5">No Opportunities Available</td>
                                </tr>)}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section className="section section-lg" id="contact">

            <div className="contactus-3">
              <div className="page-header header-filter">
                <div
                  className="page-header-image"
                  style={{
                    backgroundImage:
                      "url(" + require("Assets/img/andre-benz.jpg") + ")"
                  }}
                />
                <Container>
                  <Row>
                    <Col className="text-center" md="12">
                      <h1 className="title">Got a question?</h1>
                      <h3>We'd like to talk more about what you need</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container>
                <Row className="landingIcons">

                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-primary">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("Assets/img/feature-blob/primary.png")}
                        />
                        <i className="tim-icons icon-square-pin" />
                      </div>
                      <h4 className="info-title">Address</h4>
                      <p className="description">Los Angeles, CA</p>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-info">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("Assets/img/feature-blob/info.png")}
                        />
                        <i className="tim-icons icon-email-85" />
                      </div>
                      <h4 className="info-title">Email</h4>
                      <p className="description">&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#101;&#108;&#101;&#118;&#101;&#105;&#103;&#104;&#116;&#046;&#099;&#111;</p>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="info">
                      <div className="icon icon-warning">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("Assets/img/feature-blob/warning.png")}
                        />
                        <i className="tim-icons icon-mobile" />
                      </div>
                      <h4 className="info-title">Phone Number</h4>
                      <p className="description">(&#050;&#049;&#051;)&#032;&#051;&#049;&#054;&#045;&#054;&#056;&#049;&#048;</p>
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

const mapReduxStateToProps = (state) => ({
  user: state.auth.user,
  userId: state.auth.userId,
});

export default connect(mapReduxStateToProps)(withRouter(LandingPage));
